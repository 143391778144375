<template>
  <div class="layout-wrapper">
    <div class="header-container">
      <UiBaseTemporaryNotification />
      <PageHeader
        :title="currentPageName && $t(currentPageName)"
        :is-small="showBreadcrumbs"
        :show-breadcrumbs="showBreadcrumbs"
      >
        <TheBreadcrumbs :breadcrumbs="breadcrumbsList" />
      </PageHeader>
    </div>
    <div class="view-container">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import PageHeader from '@c/PageHeader.vue';
import TheBreadcrumbs from '@c/TheBreadcrumbs.vue';
import useBreadcrumbs from '@composables/useBreadcrumbs';
import { UiBaseTemporaryNotification } from '@ui-base';

const { showBreadcrumbs, breadcrumbsList, currentPageName } = useBreadcrumbs();
</script>
<style lang="scss" scoped>
.header-container {
  padding: $l-padding 54px 0 54px;

  @include to-sm {
    padding: $l-padding $l-padding 0;
  }
}

.view-container {
  padding: 0 54px $l-padding;

  @include to-sm {
    padding: 0 $l-padding;
  }
}
</style>

<template>
  <div class="loader">
    <div class="wrapper">
      <div class="cell d-0" />
      <div class="cell d-1" />
      <div class="cell d-2" />

      <div class="cell d-1" />
      <div class="cell d-2" />
  
      <div class="cell d-2" />
      <div class="cell d-3" />
  
      <div class="cell d-3" />
      <div class="cell d-4" />
    </div>
    <span class="text">{{ loadText }}</span>
  </div>
</template>
<script setup lang="ts">
import useLoader from '@composables/useLoader';

const { loadText } = useLoader();

const html = document.querySelector('html');
let overflow = html.style.overflow;
onMounted(() => {
  overflow = html.style.overflow;
  html.style.overflow = 'hidden';
});
onBeforeUnmount(() => {
  html.style.overflow = overflow;
});
</script>
<style lang="scss" scoped>

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.text {
  margin-top: 8px;
  position: relative;
  z-index: 1;
  text-align: center;
  width: 100%;

  @include t6;
}

.loader {
	--cell-size: 52px;
	--cell-spacing: 1px;
	--cells: 3;
	--total-size: calc(var(--cells) * (var(--cell-size) + 2 * var(--cell-spacing)));

	display: flex;
	flex-wrap: wrap;
	width: var(--total-size);
	height: var(--total-size);
	position: fixed;
	inset: 0;
	margin: auto;
	z-index: 1000;

	&::before {
		content: '';
		position: fixed;
		inset: 0;
		background: $c-white;
		z-index: 1;
	}
}

.cell {
	flex: 0 0 var(--cell-size);
	margin: var(--cell-spacing);
	background-color: transparent;
	box-sizing: border-box;
	border-radius: 4px;
	animation: 1.5s ripple ease infinite;
	z-index: 2;
}

.cell.d-1 {
	animation-delay: 100ms;
}

.cell.d-2 {
	animation-delay: 200ms;
}

.cell.d-3 {
	animation-delay: 300ms;
}

.cell.d-4 {
	animation-delay: 400ms;
}

/* Animation */
@keyframes ripple {
	0% {
		background-color: transparent;
	}

	30% {
		background-color: $c-tim-accent;
	}

	60% {
		background-color: transparent;
	}

	100% {
		background-color: transparent;
	}
}
</style>

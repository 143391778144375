import { useFieldError } from 'vee-validate';

const useValidation = (name: string) => {
  const errorMessage = useFieldError(name);

  const isInvalidState = computed(() => {
    return !!errorMessage.value;
  });

  return {
    errorMessage,
    isInvalidState,
  };
};

export default useValidation;

import baseService from '@services/baseService';
import { AddWarehouseAddress, GetWarehouseAddress, EditWarehouseAddress, WarehouseAddressSearchItem, WarehouseAddressForShipment } from './model';
import { COUNTRY } from '@enums/global.enums';
import { useRequest } from '@request';

export const getAddressListForTable = async (supplierId: string): Promise<WarehouseAddressSearchItem[]> => {
  const { callRequest } = useRequest();

  const { data } = await callRequest(baseService.get<{
    id: string,
    warehouseAddress: {
      street: string,
      buildingNumber: string,
      city: string,
      postCode: string,
      country: COUNTRY
    },
    warehouseName: string,
    collectRestrictions?: string,
    searchName?: string
  }[]>(`/suppliers/${supplierId}/warehouse_address`));

  return data || [];
};

export const getAddress = async (id: string): Promise<GetWarehouseAddress> => {
  const { callRequest } = useRequest();

  const { data } = await callRequest(baseService.get<{
    id: string,
    warehouseAddress: {
      street: string,
      buildingNumber: string,
      city: string,
      postCode: string,
      country: COUNTRY
    },
    warehouseName: string,
    collectRestrictions?: string,
  }>(`/suppliers/warehouse_addresses/${id}`));

  return data;
};

export const addAddress = async (payload: AddWarehouseAddress): Promise<{ hasError: boolean }> => {
  const { callRequest } = useRequest();

  return await callRequest(baseService.post(`/suppliers/warehouse_addresses`, payload));
};

export const editAddress = async (addressId: string, payload: EditWarehouseAddress): Promise<{ hasError: boolean }> => {
  const { callRequest } = useRequest();

  return await callRequest(baseService.patch(`/suppliers/warehouse_addresses/${addressId}`, payload));
};

export const deleteAddress = async (id: string): Promise<{ hasError: boolean }> => {
  const { callRequest } = useRequest();

  return await callRequest(baseService.delete(`/suppliers/warehouse_addresses/${id}`));
};

export const getAddressListForShipment = async (supplierId: string): Promise<WarehouseAddressForShipment[]> => {
  const { callRequest } = useRequest();

  const { data } = await callRequest(
    baseService.get<{
      id: string,
      warehouseAddress: {
        street: string,
        buildingNumber: string,
        city: string,
        postCode: string,
        country: COUNTRY
      },
      warehouseName: string,
      collectRestrictions?: string,
      searchName?: string
    }[]>(`/suppliers/${supplierId}/warehouse_address`),
    {
      transformData: (data) => data.map((address) => ({
        name: address.warehouseName || '',
        street: address.warehouseAddress.street || '',
        buildingNumber: address.warehouseAddress.buildingNumber || '',
        city: address.warehouseAddress.city || '',
        postCode: address.warehouseAddress.postCode || '',
        searchName: address.searchName,
      })),
      errorFallbackData: () => [],
    },
  );

  return data;
};

import WarehouseAddressNotification from './components/WarehouseAddressNotification.vue';
import WarehouseAddressSelectList from './components/WarehouseAddressSelectList.vue';
import WarehouseAddressTableList from './components/WarehouseAddressTableList.vue';
import localMessages from './local-messages';

export const initPackage = () => {
  const { mergeLocaleMessage } = useI18n();
  mergeLocaleMessage('pl', localMessages);
};

export const components = {
  WarehouseAddressNotification,
  WarehouseAddressSelectList,
  WarehouseAddressTableList,
};

import { defineStore } from 'pinia';
import { SUPPLIER_STATUS } from '../model';

export const useSupplierCommon = defineStore('supplier-common-use-supplier-common', () => {
  const currentSupplier = ref({
    status: SUPPLIER_STATUS.NEW,
    id: undefined as string | undefined,
  });
  
  const setSupplierStatus = (status: SUPPLIER_STATUS = SUPPLIER_STATUS.NEW) => {
    currentSupplier.value.status = status;
  };

  const setSupplierId = (id?: string) => {
    currentSupplier.value.id = id;
  };

  const isSupplierActive = computed(() => currentSupplier.value.status === SUPPLIER_STATUS.ACTIVE);

  return {
    currentSupplier,
    isSupplierActive,
    setSupplierStatus,
    setSupplierId,
  };
});

import { useDropZone, useFileDialog } from '@vueuse/core';
import { ValidatedFile } from '../../model/File';

export const useFileList = (payload: {
  isDisabled: Ref<boolean>,
  dropElement?: Ref<HTMLElement>,
  onFileChange?: (files: ValidatedFile[]) => void,
}) => {
  const fileList = ref<ValidatedFile[]>([]);

  const assignFileList = (files: File[] | null | FileList) => {
    fileList.value = [...fileList.value, ...Array.from(files || [])].map((file) => {
      const sizeMB = file.size / 1024 / 1024;
      const validatedFile: ValidatedFile = file as ValidatedFile;
      validatedFile.isInvalidSize = sizeMB >= 25;
      return validatedFile;
    });
    payload?.onFileChange?.(fileList.value);
  };
  const deleteFile = (index: number) => {
    fileList.value = fileList.value.filter((_, i) => i !== index);
    payload?.onFileChange?.(fileList.value);
  };

  const clearFileList = () => {
    fileList.value = [];
    payload?.onFileChange?.(fileList.value);
  };

  const { open, onChange } = useFileDialog({ reset: true });
  onChange(assignFileList);

  const openFileDialog = () => {
    if (payload.isDisabled.value) {
      return;
    }

    open();
  };

  if (payload.dropElement) {
    const handleDrop = (files: File[]) => {
      if (payload.isDisabled.value) {
        return;
      }
    
      assignFileList(files);
    };
    const { isOverDropZone } = useDropZone(payload.dropElement.value, { onDrop: handleDrop });

    return {
      fileList,
      openFileDialog,
      deleteFile,
      clearFileList,
      isOverDropZone,
    };
  }

  return {
    fileList,
    openFileDialog,
    deleteFile,
    clearFileList,
  };
};

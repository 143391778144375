<template>
  <div class="warehouse-address-table-list">
    <UiTableTableContainer
      :loading="isListLoading"
      :is-all-selected="false"
      :hide-search="true"
      :hide-all-selector="true"
      :allow-empty-body="true"
      class="table"
    >
      <template #table-header>
        <UiTableCellItem
          v-for="header in columns"
          :key="header.title"
          :col-id="header.colId"
          bold
        >
          {{ header.title }}
        </UiTableCellItem>
      </template>
      <template #table-body>
        <UiTableRowItem
          v-for="address in addressListForTable"
          :key="address.id"
          :row-id="address.id"
          :hover="true"
        >
          <UiTableCellItem col-id="warehouseName">
            {{ address.warehouseName }}
          </UiTableCellItem>
          <UiTableCellItem col-id="warehouseAddress">
            {{ createAddressFullName(address) }}
          </UiTableCellItem>
          <UiTableCellItem col-id="collectRestrictions">
            {{ address.collectRestrictions }}
          </UiTableCellItem>
          <template
            v-if="!address.searchName"
            #options
          >
            <AddressTableItemActions
              :address-id="address.id"
              :is-disabled-delete="addressListForTable.length === 1"
              @address-changed="getAddressList"
            />
          </template>
        </UiTableRowItem>
        <span
          v-if="!isListLoading && !addressListForTable.length"
          class="empty-results"
        >
          {{ $t('warehouseAddressSupplier.emptyResults') }}
        </span>
      </template>
    </UiTableTableContainer>
    <AddAddressTableButton
      class="button"
      :supplier-id="supplierId"
      :loading="isListLoading"
      @address-created="getAddressList"
    />
  </div>
</template>

<script setup lang="ts">
import { UiTableTableContainer, UiTableCellItem, UiTableRowItem, TableHeaderItem } from '@ui-table';
import { WarehouseAddressSearchItem } from '../model';
import { getAddressListForTable } from '../service';
import AddAddressTableButton from './AddAddressTableButton.vue';
import AddressTableItemActions from './AddressTableItemActions.vue';

interface Props {
  supplierId: string,
}

const { t } = useI18n();
const props = defineProps<Props>();

const addressList = ref<WarehouseAddressSearchItem[]>([]);
const isListLoading = ref(true);
const getAddressList = async () => {
  isListLoading.value = true;

  addressList.value = await getAddressListForTable(props.supplierId);

  isListLoading.value = false;
};
getAddressList();

const addressListForTable = computed(() => addressList.value);

const createColumn = (title: string, key: string) => ({
  title,
  field: key,
  colId: key,
});
const columns = computed<TableHeaderItem[]>(() => [
  createColumn(t('warehouseAddressSupplier.form.warehouseName'), 'warehouseName'),
  createColumn(t('warehouseAddress'), 'warehouseAddress'),
  createColumn(t('warehouseAddressSupplier.form.collectRestrictions'), 'collectRestrictions'),
]);

const createAddressFullName = ({ warehouseAddress }: WarehouseAddressSearchItem) => `${warehouseAddress.street} ${warehouseAddress.buildingNumber}, ${warehouseAddress.city} ${warehouseAddress.postCode}`;

const hasAnyAddress = computed(() => isListLoading.value ? true : !!addressList.value.length);
defineExpose({ hasAnyAddress });
</script>
<style scoped lang="scss">
.warehouse-address-table-list {
  display: flex;
  flex-direction: column;
}

.table {
  :deep(.row-item) {
    grid-template-columns: 30% 40% 30%;
  }

  :deep(.content-container__inner) {
    min-width: 198px;
  }
}

.button {
  margin-left: auto;
  margin-top: 8px;
}

.empty-results {
  padding: 8px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $c-tim-gray-light;
}
</style>

<template>
  <UiTableActionList :actions="actions" />
</template>

<script setup lang="ts">
import EditAddressModal from './EditAddressModal.vue';
import { useModal } from '@ui-base';
import { deleteAddress } from '../service';
import { TableActionItem, UiTableActionList } from '@ui-table';

interface Props {
  addressId: string,
  isDisabledDelete: boolean,
}

const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'address-changed'): void
}>();

const { showModal, showConfirmationModal } = useModal();

const actions = computed<TableActionItem[]>(() => {
  return [
    {
      name: 'edit',
      isDisabled: false,
      onTrigger: () => showModal(
        EditAddressModal,
        {
          addressId: props.addressId,
          refreshData: () => $emit('address-changed'),
        },
      ),
      text: 'Edytuj',
      icon: 'edit',
    },
    {
      name: 'delete',
      isDisabled: props.isDisabledDelete,
      onTrigger: () => showConfirmationModal({
        content: 'Czy chcesz usunąć pozycję z listy?',
        callback: async () => {
          await deleteAddress(props.addressId);
          $emit('address-changed');
        },
      }),
      text: 'Usuń',
      icon: 'delete',
    },
  ];
});
</script>

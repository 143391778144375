import { defineRule } from 'vee-validate';
import i18n from '../locales';

export const numberRegex = /\d/;
export const upperCaseRegex = /[A-Z]/;
export const lowerCaseRegex = /[a-z]/;

defineRule('before', (
  value: string, // BaseDatepicker returns FormatedDate format
  [beforeTarget]: [Date | ''],
) => {
  if (!beforeTarget) {
    return true;
  }

  const dateValue = new Date();
  const [year, month, day] = value.split('-').map(Number);
  dateValue.setMonth(month - 1);
  dateValue.setDate(day);
  dateValue.setFullYear(year);
  dateValue.setHours(0, 0, 0, 0);

  const dateBeforeTarget = new Date(beforeTarget);
  dateBeforeTarget.setHours(0, 0, 0, 0);

  const displayDate = `${dateBeforeTarget.getFullYear()}-${dateBeforeTarget.getMonth() + 1}-${dateBeforeTarget.getDate()}`;

  return dateValue.getTime() <= dateBeforeTarget.getTime() || `Wprowadzona data musi być mniejsza lub równa dacie ${displayDate}`;
});

defineRule('maxLength', (value, [limit]) => {
  return String(value || '').length <= Number(limit) || i18n.global.t('validators.maxLength', { limit });
});

defineRule('minLength', (value, [limit]) => {
  return String(value || '').length >= Number(limit) || i18n.global.t('validators.minLength', { limit });
});

const createPriceValue = (value: string | number) => parseFloat(String(value || 0).replace(',', '.'));
defineRule('minValue', (value, [limit]) => {
  return createPriceValue(value) >= createPriceValue(limit) || i18n.global.t('validators.minValue', { limit });
});

defineRule('maxValue', (value, [limit]) => {
  return createPriceValue(value) <= createPriceValue(limit) || i18n.global.t('validators.maxValue', { limit });
});

defineRule('requiredRule', (value: string): boolean | string => {
  return !!value || i18n.global.t('validators.requiredRule');
});

defineRule('emailRule', (value: string): boolean | string => {
  const regexEmail = /^(([^<>()[\]\\.,:\s@']+(\.[^<>()[\]\\.,:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
  return value && (regexEmail.test(value) || i18n.global.t('validators.emailRule'));
});

defineRule('postCodeRule', (value: string): boolean | string => {
  const regexPostCode = /^([0-9]{2})(-[0-9]{3})?$/gi;
  return value && ((regexPostCode.test(value) && value !== '00-000') || i18n.global.t('validators.postCodeRule'));
});

defineRule('phoneRule', (value: string): boolean | string => {
  const regexPhone = /(\+\d{11,20})$|(\d{9,20})$/y;
  return value && (regexPhone.test(value) || i18n.global.t('validators.phoneRule'));
});

defineRule('nipRule', (value: string): boolean | string => {
  const regexNip = /^((\d{3}\d{3}\d{2}\d{2})|(\d{3}\d{2}\d{2}\d{3}))$/g;
  return value && (regexNip.test(value) || i18n.global.t('validators.nipRule'));
});

defineRule('passwordRule', (value: string): boolean => {
  const hasMinEight = value.length >= 8;
  const hasLowerCase = lowerCaseRegex.test(value);
  const hasUpperCase = upperCaseRegex.test(value);
  const hasNumber = numberRegex.test(value);

  return hasMinEight && hasLowerCase && hasUpperCase && hasNumber;
});

defineRule('passwordConfirmRule', (value: string, [target]: string, ctx): boolean | string => {
  return value === ctx.form[target] || i18n.global.t('validators.passwordConfirmRule');
});

defineRule('glnOrIlnRule', (value: string): boolean | string => {
  const regexGlnOrIln = /^\d{13}$/;
  return value && (regexGlnOrIln.test(value) || i18n.global.t('validators.glnOrIlnRule'));
});

defineRule('urlRule', (value) => {
  const regexUrl = /(ftp|https?):\/\/(www\.)?[\w\-.@:%_+~#=]+\.\w{2,3}(\/\w+)*(\?.*)?/;
  return value && (regexUrl.test(value) || i18n.global.t('validators.urlRule'));
});

defineRule('eanRule', (value: string): boolean => {
  if(!value) {
    return true;
  }

  if (!value.match(/^(\d{8}|\d{12,14})$/)) {
    return false;
  }

  const paddedValue: string = value.padStart(14, '0');

  let result = 0;
  for (let i = 0; i < paddedValue.length - 1; i += 1) {
    result += parseInt(paddedValue.charAt(i), 10) * ((i % 2 === 0) ? 3 : 1);
  }

  return ((10 - (result % 10)) % 10) === parseInt(paddedValue.charAt(13), 10);
});

<template>
  <div class="page-view-content-wrapper">
    <slot />
  </div>
</template>
<script setup lang="ts">
type Props = {
  padding?: string
}
const props = withDefaults(defineProps<Props>(), {
  padding: '16px',
});
</script>
<style lang="scss" scoped>
.page-view-content-wrapper {
  border-top: 1px solid $c-tim-gray-light;
  padding: v-bind('props.padding');
  display: flex;
  flex-direction: column;
  background: $c-white;
  overflow: auto;
  position: relative;
}
</style>

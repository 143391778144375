<template>
  <div
    class="the-toast"
    data-prevent-outside-close
  >
    <TransitionGroup name="slide">
      <GlobalNotification
        v-for="item in toastList"
        :key="item.id"
        class="the-toast__notification"
        :model-value="true"
        :type="item.type"
        margin="0 0 8px 0"
        @update:model-value="closeToast(item.id)"
      >
        <span class="the-toast__text">
          {{ item.text }}
        </span>
      </GlobalNotification>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import useToast from '@composables/useToast';

const { toastList, closeToast } = useToast();
</script>

<style lang="scss" scoped>

.the-toast {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  z-index: 251;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  user-select: none;

  &__notification {
    max-width: 500px;
    pointer-events: visible;
    user-select: auto;
  }

  &__text {
    white-space: normal;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform $base-transition;
}

.slide-enter-active,
.slide-enter,
.slide-leave-to {
  transform: translateX(100vw);
}

.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}
</style>

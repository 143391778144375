<template>
  <div class="message-box">
    <div class="header">
      <div class="user-info">
        <div
          class="user-full-name"
          data-t="message-box-user-full-name"
        >
          <img
            v-if="isAdmin"
            class="tim-logo"
            src="/images/logo_tim_new.jpg"
            alt="TIM"
            data-t="message-box-tim-logo"
          >
          {{ status === MESSAGE_STATUS.ACTIVE ? 'Ty' : user.fullName }}
        </div>
        <div
          v-if="user.role"
          class="user-role"
          data-t="message-box-user-role"
        >
          {{ user.role }}
        </div>
      </div>
      <div
        class="created-at"
        data-t="message-box-created-at"
      >
        {{ createdAt }}
      </div>
    </div>
    <div
      class="content"
      data-t="message-box-content"
      :class="className"
    >
      <div
        v-if="isSending"
        class="sending-loader"
      >
        <GlobalLoader />
      </div>
      <slot>
        {{ content }}
      </slot>
      <div class="content__file-list">
        <template
          v-for="(file, index) in orderedFileList"
          :key="index"
        >
          <FileDisplayImage
            v-if="isFileImage(file.type)"
            :file="file"
            :user-full-name="user.fullName"
            :created-at="createdAt"
          />
          <FileDisplayDownload
            v-else
            :file="file"
            class="content__file"
          />
        </template>
      </div>
    </div>
    <span
      v-if="hasError"
      class="error-message"
      data-t="error-message"
    >Wystąpił problem podczas wysyłania wiadomości</span>
  </div>
</template>
<script setup lang="ts">
import { isFileImage } from '../../helpers';
import { MESSAGE_STATUS, MessageItemWithStatus } from '../../model';
import FileDisplayDownload from './file/FileDisplayDownload.vue';
import FileDisplayImage from './file/FileDisplayImage.vue';
import _cloneDeep from 'lodash/cloneDeep';

type Props = MessageItemWithStatus
const props = defineProps<Props>();

const className = computed(() => {
  if (props.hasError) {
    return 'is-error';
  }

  switch(props.status) {
    case MESSAGE_STATUS.ACTIVE:
      return 'is-active';
    case MESSAGE_STATUS.ASSIGNED_ADMIN:
    case MESSAGE_STATUS.ASSIGNED_SUPPLIER:
      return 'is-assigned';
    case MESSAGE_STATUS.ADDITIONAL_ADMIN:
      return 'is-additional';
    default:
      return '';
  }
});

const isAdmin = computed(() => [MESSAGE_STATUS.ASSIGNED_ADMIN, MESSAGE_STATUS.ADDITIONAL_ADMIN].includes(props.status));

const orderedFileList = computed(() => {
  return _cloneDeep(props.fileList).sort((a, b) => a.type.localeCompare(b.type));
});
</script>
<style lang="scss" scoped>
$gap: 4px;

.message-box {
  display: flex;
  flex-wrap: wrap;
  grid-gap: $gap;
  overflow: hidden;
  position: relative;
}

.sending-loader {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: $base-radius;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba($c-white, 0.5);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  min-height: 48px;
  padding: 12px;
  border-radius: $base-radius;
  position: relative;

  &__text {
    width: 100%;
    white-space: pre-wrap;
  }

  &__file-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    grid-gap: $gap;
    width: 100%;
  }

  &__file {
    max-width: 306px;
  }

  &.is-active {
    background-color: $c-tim-blue-light;
  }

  &.is-assigned {
    background-color: $c-tim-green-light;
  }

  &.is-additional {
    background-color: $c-tim-gray-lightest;
  }

  &.is-error {
    background-color: $c-tim-red-light;
  }
}

.error-message {
  @include t9-bold;

  color: $c-tim-red;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
}

.user-info {
  display: grid;
  grid-gap: $gap;
  width: 100%;
}

.user-full-name,
.user-role {
  @include one-line-text-with-dots;
}

.user-full-name {
  @include t9-bold;

  height: 16px;
  display: inline-flex;
  align-items: center;
}

.tim-logo {
  max-width: 100%;
  max-height: 100%;
  margin-right: 4px;
}

.user-role {
  @include t9;

  color: $c-tim-gray-dark;
}

.created-at {
  @include t9;

  display: inline-flex;
  align-items: center;
  color: $c-tim-gray-dark;
}
</style>

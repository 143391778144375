import App from './App.vue';
import './style/index.scss';
import router from './router';
import '@helpers/validators';
import i18n from './locales';
import { createPinia } from 'pinia';
import { addSentry } from '@sentry';

const pinia = createPinia();
export const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(pinia);

addSentry(app, router).then(() => {
  app.mount('#app');
});

import axios, { Axios, AxiosError } from 'axios';
import { useUserCommon } from '@user-common';
import $router from '../router';
import { ROUTER_LINKS } from '@enums/global.enums';
import { ERROR_AUTH_CODES } from '@request';

export const generateCancelToken = () => axios.CancelToken.source();
const JWT_ERROR_CODES = [
  ERROR_AUTH_CODES.EXPIRED_TOKEN,
  ERROR_AUTH_CODES.MISSING_TOKEN,
  ERROR_AUTH_CODES.INVALID_TOKEN,
] as const;
type JwtErrorCode = typeof JWT_ERROR_CODES[number];

const baseService: Axios = axios.create({
  baseURL: (import.meta.env.VITE_BACKEND_URL as string),
  withCredentials: true,
});

baseService.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['X-Authorization'] = `Bearer ${token}`;
  }

  switch(config.method.toLowerCase()) {
    case 'put':
    case 'post': {
      // NOTE: this force content type as application/json for empty content
      if (!config.data) {
        config.data = {};
      }

      // NOTE: this force content type as application/json as default
      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }

      break;
    }
    case 'patch': {
      config.headers['Content-Type'] = 'application/merge-patch+json';
      break;
    }
  }

  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  return config;
});

baseService.interceptors.response.use(
  response => response,
  async (error: AxiosError<{ code?: JwtErrorCode }>) => {
    if(JWT_ERROR_CODES.includes(error.response?.data?.code)) {
      // NOTE: clear session and roload when token is outdated
      const userCommon = useUserCommon();
      userCommon.logout();
    }
    if(error.response?.status === 503) {
      // NOTE: if service is not avaiable we are redirecting to the error page
      $router.push(ROUTER_LINKS.ERROR_503);
    }
    return Promise.reject(error);
  },
);

export function toFormData(payload: { [key: string]: string | Blob | Partial<File> }) {
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    formData.append(key, payload[key] as string | Blob);
  });
  return formData;
}

export default baseService;


import baseService from '@services/baseService';
import { useRequest } from '@request';
import { MessageCountMap, SegmentList, ThreadRequireUserAction } from '../model';
import { mapMessageCountMap, mapSegmentList } from '../mappers';

export const getSegmentList = async (): Promise<SegmentList> => {
  const { callRequest } = useRequest();

  const { data } =  await callRequest<
  {
    id: string,
    name: string,
  }[],
  SegmentList
  >(
    baseService.get<{
      id: string,
      name: string,
    }[]>(`/segments`),
    {
      transformData: (list) => mapSegmentList(list),
      errorFallbackData: () => mapSegmentList([]),
    },
  );

  return data;
};

export const getMessageCountList = async (): Promise<{ messageCountList: MessageCountMap, isUnread: ThreadRequireUserAction }> => {
  const { callRequest } = useRequest();

  const { data } =  await callRequest(
    baseService.get<{
      active: number, 
      finished: number, 
      deleted: number, 
      all: number,
      isUnread: boolean,
    }>(`/thread/count`),
    {
      transformData: (messageCountList) => ({
        messageCountList: mapMessageCountMap(messageCountList),
        isUnread: !!messageCountList.isUnread as ThreadRequireUserAction,
      }),
      errorFallbackData: () => ({
        messageCountList: mapMessageCountMap({
          active: 0,
          deleted: 0,
          all: 0,
          finished: 0,
        }),
        isUnread: false as ThreadRequireUserAction,
      }),
    },
  );

  return data;
};

export const getUnreadThreadsCount = async (): Promise<{ data: number }> => {
  const { callRequest } = useRequest();

  return await callRequest(baseService.get('/users/reload'), {
    transformData:(data) => data.user.unreadThreadsCount,
    errorFallbackData: () => 0,
  });
};

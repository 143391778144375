<template>
  <component
    :is="modalComponent"
    v-if="modalComponent"
    v-bind="modalProps"
  />
</template>

<script setup lang="ts">
import { useModal } from '../../composables/useModal';

const { modalComponent, modalProps } = useModal();
</script>

export default {
  feedback: {
    success: 'Raport został wysłany',
    header: 'Zgłoś uwagi',
    paragraph: 'Znalazłeś błąd, masz trudność z jakąś funkcjonalnością? Może posiadasz pomysł na usprawnienie Panelu Dostawcy? Wyślij nam zgłoszenie - postaramy się pomóc i spełnić Twoje oczekiwania. Odpowiedź otrzymasz na adres mailowy wskazany w formularzu.',
    reportType: 'Rodzaj zgłoszenia',
    reportContent: 'Treść zgłoszenia',
    confirm: 'Wyślij zgłoszenie',
    reportTypeList: {
      0: 'Błąd techniczny na stronie',
      1: 'Problem z dodaniem lub edycją produktów',
      2: 'Pomysł na nową funkcjonalność',
    },
    fileButton: 'Dodaj plik',
    policy: {
      short: 'Administratorem danych osobowych podawanych przez Ciebie w ww. formularzu „ZGŁOŚ UWAGI” jest TIM S.A. z siedzibą we Wrocławiu. Kontakt z administratorem możliwy jest listownie, pocztą e-mail na adres {email}. Administrator wyznaczył Inspektora Ochrony Danych – Panią Roksanę Lejpamer, z którym kontakt możliwy jest pocztą e-mail na adres {email}.',
      full: 'Szczegółowe dane administratora: TIM S.A. z siedzibą we Wrocławiu (53-612) przy ul. Jaworskiej 13, wpisana w Rejestrze Przedsiębiorców Krajowego Rejestru Sądowego prowadzonym przez Sąd Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu, VI Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000022407, NIP 897-000-96-78, kapitał zakładowy 22 199 200,00 PLN. Twoje dane osobowe będą przetwarzane w celu prowadzenia z Tobą komunikacji oraz rozpatrzenia sprawy zainicjowanej przez Ciebie jako prawnie uzasadniony interes administratora (art. 6 ust. 1 lit. f RODO). Będziemy je przetwarzać do czasu wniesienia przez Ciebie skutecznego sprzeciwu, przy czym nie dłużej niż przez okres przedawnienia roszczeń mogących wyniknąć w związku z przedmiotem danej komunikacji. Masz prawo do dostępu do danych osobowych, ich sprostowania, usunięcia oraz ograniczenia przetwarzania, jak również prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (ul. Stawki 2, 00-193 Warszawa). Przysługuje Ci prawo do zgłoszenia sprzeciwu wobec przetwarzania danych osobowych. Odbiorcami danych mogą być podmioty, którym Administrator powierzy przetwarzanie danych, w tym na przykład podmioty dostarczające infrastrukturę techniczną wykorzystywaną w celu prowadzenia Modułu dostawców. Podanie ww. danych osobowych jest dobrowolne, jednakże odmowa ich podania uniemożliwia prowadzenie z Tobą komunikacji oraz rozpatrzenia sprawy zainicjowanej przez Ciebie. Więcej informacji na temat przetwarzania Twoich danych osobowych przez TIM S.A. w związku z korzystaniem przez Ciebie z Modułu dostawców oraz przysługujących Ci praw znajdziesz w ',
    },
  },
} as const;

<template>
  <div
    data-t="base-loader"
    class="base-loader"
    :class="{ 'is-infinite': !props.buffer, 'is-buffer': props.buffer }"
  >
    <IconBaseLoader />
  </div>
</template>

<script lang="ts" setup>
import IconBaseLoader from '/src/assets/icons/icon-base-loader.svg?component';

interface Props {
  size?: string,
  buffer?: boolean,
}
const props = withDefaults(defineProps<Props>(), {
  size: '32px',
  buffer: false,
});
</script>

<style lang="scss" scoped>
.base-loader {
  color: $c-tim-blue;
  transform: rotate(-90deg);
  width: v-bind("props.size");
  height: v-bind("props.size");

  &.is-infinite {
    animation: circular-progress 2s infinite;
  }

  &.is-buffer {
    animation: buffer-animation 10s cubic-bezier(0.25, 0.5, 0.75, 1) forwards;
  }
}

@keyframes circular-progress {
  from {
    stroke-dashoffset: 94;
  }

  to {
    stroke-dashoffset: -94;
  }
}

@keyframes buffer-animation {
  from {
    stroke-dashoffset: 94;
  }

  to {
    stroke-dashoffset: 5;
  }
}
</style>

<template>
  <PanelTrigger
    :is-active="false"
    :unread-threads-count="userCommon.unreadThreadsCount"
    @click="handleOpenPanel"
  />
</template>
<script setup lang="ts">
import { useUserCommon } from '@user-common';
import PanelTrigger from '../common/PanelTrigger.vue';
import { ADMIN_ROUTER_LINKS } from '@enums/admin.enums';

const userCommon = useUserCommon();

const router = useRouter();
const handleOpenPanel = () => router.push(ADMIN_ROUTER_LINKS.ADMIN_PANEL_COMMUNICATION);
</script>

<template>
  <span @click.prevent="handleFileClick">
    <slot />
  </span>
</template>
<script setup lang="ts">
import { FileDownloadProps } from '../model';
import { startFileDownload } from '../service';

const props = defineProps<FileDownloadProps>();
  
const handleFileClick = async () => {
  startFileDownload(props);
};
</script>
<style lang="scss" scoped>
span {
  cursor: pointer;
}
</style>

export const enum SUPPLIER_STATUS {
  NEW = 'NEW',
  REGISTERED = 'REGISTERED',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED'
}

export const enum SUPPLIER_TYPES {
  COMPLEMENTARY = 'COMPLEMENTARY',
  COMPLEMENTARY_DROPSHIPPING = 'COMPLEMENTARY_DROPSHIPPING',
  WAREHOUSE = 'WAREHOUSE',
  WAREHOUSE_DROPSHIPPING = 'WAREHOUSE_DROPSHIPPING',
  SERVICE = 'SERVICE',
  SERVICE_DROPSHIPPING = 'SERVICE_DROPSHIPPING',
}

export type LiveSelectSupplierItem = {
  id: string,
  name: string
}

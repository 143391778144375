const expandedRows = ref([]);

export default () => {
  const resetExpandedRows = () => expandedRows.value = [];
  const toggleRow = (id: string) => {
    if (expandedRows.value.includes(id)) {
      expandedRows.value = expandedRows.value.filter(rowId => rowId !== id);
    } else {
      expandedRows.value.push(id);
    }
  };
  const isExpanded = (id: string): boolean => expandedRows.value.includes(id);

  return {
    resetExpandedRows,
    expandedRows,
    toggleRow,
    isExpanded,
  };
};

<template>
  <BaseSelect
    id="warehouseAddressSearchList"
    v-model="selectValue"
    name="warehouseAddressSearchList"
    :item-list="addressListItems"
    :is-disabled="isListLoading"
  />
</template>

<script setup lang="ts">
import { BaseSelect } from '@ui-form';
import { WarehouseAddressForShipment } from '../model';
import { getAddressListForShipment } from '../service';
import _omit from 'lodash/omit';

interface Props {
  supplierId: string,
  modelValue: WarehouseAddressForShipment['name'],
  isRaben: boolean
}

const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'update:modelValue', value: WarehouseAddressForShipment): void
}>();

const addressList = ref<WarehouseAddressForShipment[]>([]);
const isListLoading = ref(true);
const getAddressList = async () => {
  isListLoading.value = true;

  if (!addressList.value.length) {
    addressList.value = await getAddressListForShipment(props.supplierId);
  }

  selectFirstAddress();

  isListLoading.value = false;
};
getAddressList();

const addressListForSelect = computed(() => props.isRaben ? addressList.value.filter(item => !!item.searchName) : addressList.value);
const addressListItems = computed(() => addressListForSelect.value.map(({ name }) => ({ text: name, id: name })));
const selectValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    const address = addressListForSelect.value.find(({ name }) => name === value);
    if (address) {
      // Sends searchName only for Raben/Pallets
      const validAddress = props.isRaben ? address : _omit(address, 'searchName');
      $emit('update:modelValue', validAddress);
    }
  },
});

function selectFirstAddress() {
  selectValue.value = addressListForSelect.value[0].name;
}

watch(
  () => props.isRaben,
  selectFirstAddress,
);
</script>

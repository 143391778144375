<template>
  <PageViewWrapper>
    <slot name="header" />
    <PageViewContentWrapper
      ref="contentRef"
      padding="0"
      class="content"
    >
      <ContentLoader v-if="!threadDetails" />
      <MessageBoxList
        v-else
        :message-list="threadDetails.messageList"
        :admin="threadDetails.admin"
        :supplier="threadDetails.supplier"
        :logged-user="loggedUser"
      />
      <div class="message-send-textarea">
        <ContentLoader v-if="!threadDetails" />
        <MessageSendTextarea
          v-else-if="threadDetails.status === THREAD_STATUS.ACTIVE"
          :is-loading="false"
          @send="handleSend"
        />
        <GlobalNotification
          v-else
          :model-value="true"
          :type="BASE_NOTIFICATION_TYPES.ERROR"
          :closeable="false"
          margin="0"
        >
          <div class="notification-content">
            Ten wątek został zakończony przez administratora.
            <span class="notification-content__subtext">Jeżeli problem nie został rozwiązany prosimy o wysłanie nowej wiadomości.</span>
          </div>
        </GlobalNotification>
      </div>
    </PageViewContentWrapper>
  </PageViewWrapper>
</template>
<script setup lang="ts">
import { LoggedUser, SupplierThreadDetails, THREAD_STATUS } from '../../../model';
import { CreateSupplierMessageInThread } from '../../../model/Payload';
import MessageBoxList from '../../common/MessageBoxList.vue';
import MessageSendTextarea from '../../common/MessageSendTextarea.vue';
import ContentLoader from '../../common/ContentLoader.vue';
import PageViewContentWrapper from '../../decorators/PageViewContentWrapper.vue';
import PageViewWrapper from '../../decorators/PageViewWrapper.vue';
import { BASE_NOTIFICATION_TYPES } from '@ui-base';
import { useMessageCreate } from '../../../composables/supplier/useMessageCreate';
import { useScrollMessageBox } from '../../../composables/common/useScrollMessageBox';

type Props = {
  loggedUser: LoggedUser
  threadDetails: Pick<SupplierThreadDetails, 'messageList' | 'admin' | 'supplier' | 'id' | 'status'> | null
}
const props = defineProps<Props>();

const { createMessage } = useMessageCreate();
const handleSend = async (payload: { input: string, files: File[] }) => {
  await createMessage(props.loggedUser.id, {
    threadId: props.threadDetails.id,
    content: payload.input as CreateSupplierMessageInThread['content'],
    files: payload.files,
  });
};

const contentRef = ref<InstanceType<typeof PageViewContentWrapper> | null>(null);
const messageList = computed(() => props.threadDetails?.messageList || []);
const { setScrollToBottom } = useScrollMessageBox();
setScrollToBottom(
  contentRef,
  messageList,
);
</script>
<style lang="scss" scoped>
.content {
  padding-bottom: 72px;
  background: $c-tim-gray-lightest-alt;
}

.message-send-textarea {
  min-height: 72px;
  padding: 12px;
  background: $c-white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.notification-content {
  display: flex;
  flex-direction: column;

  &__subtext {
    @include t9;
  }
}
</style>

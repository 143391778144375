import { useCookies } from '@vueuse/integrations/useCookies';
import { FEATURE_FLAGS } from '../model';

export const useFeatureFlag = (flagName: FEATURE_FLAGS) => {
  const cookies = useCookies();
  
  const isFlagEnabled = computed<boolean>(() => cookies.get(flagName));

  const returnForFlag = <Payload, Fallback>(payload: Payload, fallback: Fallback): Payload | Fallback => {
    if (isFlagEnabled.value) {
      return payload;
    }

    return fallback;
  };

  return {
    isFlagEnabled,
    returnForFlag,
  };
};

<template>
  <PageViewWrapper>
    <slot name="header" />
    <PageViewContentWrapper>
      <NewThreadForm
        :is-loading="isSending"
        :segment="segment"
        @cancel="$emit('cancel')"
        @submit="handleMessageSend"
      />
    </PageViewContentWrapper>
  </PageViewWrapper>
</template>
<script setup lang="ts">
import { SegmentItem } from '../../../model';
import { CreateSupplierThread } from '../../../model/Payload';
import { createNewThread } from '../../../service/supplier';
import NewThreadForm from '../NewThreadForm.vue';
import PageViewContentWrapper from '../../decorators/PageViewContentWrapper.vue';
import PageViewWrapper from '../../decorators/PageViewWrapper.vue';

type Props = {
  segment: SegmentItem
}
defineProps<Props>();
const $emit = defineEmits<{
  (event: 'cancel'): void
  (event: 'thread-created'): void
}>();

const isSending = ref(false);
const handleMessageSend = async (payload: CreateSupplierThread) => {
  isSending.value = true;
  const { hasError } = await createNewThread(payload);
  if (hasError) {
    isSending.value = false;
    return;
  }
  $emit('thread-created');
  isSending.value = false;
};
</script>

const loadList = ref(new Set(['page']));
const debounceList = new Map();
const loadText = ref('');

export default () => {
  const isLoading = computed(() => loadList.value.size > 0);

  const startLoading = (key: string = 'page', text?: string) => {
    loadList.value.add(key);
    loadText.value = text || '';
  };

  const stopLoading = (key: string = 'page') => {
    clearTimeout(debounceList.get(key));
    debounceList.set(key, setTimeout(() => {
      loadList.value.delete(key);
    }, 1000));
  };

  return {
    isLoading,
    startLoading,
    stopLoading,
    loadText,
  };
};

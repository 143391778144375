<template>
  <GlobalDropdown
    class="desktop-element"
    placement="bottom-start"
  >
    <template #trigger>
      <component
        :is="navigationElement.to ? 'GlobalLink' : 'div'"
        v-if="navigationElement.text"
        class="desktop-element__link"
        :to="navigationElement.to"
      >
        {{ navigationElement.text }}
        <GlobalIcon
          v-if="navigationElement.subitems"
          name="expand_more"
        />
      </component>
    </template>
    <GlobalList
      v-show="navigationElement.subitems"
      max-width="250px"
      class="desktop-element__menu"
    >
      <GlobalListElement
        v-for="subitem in navigationElement.subitems"
        :key="subitem.text"
        dense
        :active="route.path === subitem.to"
        class="menu__list"
      >
        <GlobalLink
          v-if="subitem.to"
          class="menu__link"
          :to="subitem.to"
        >
          {{ subitem.text }}
        </GlobalLink>
        <div
          v-else
          class="menu__link"
          v-on="subitem.on"
        >
          {{ subitem.text }}
        </div>
      </GlobalListElement>
    </GlobalList>
  </GlobalDropdown>
</template>

<script setup lang="ts">
import { NavigationElement } from '../model';

interface Props {
  navigationElement: NavigationElement
}
defineProps<Props>();

const route = useRoute();
</script>

<style lang="scss" scoped>

.desktop-element {
  height: 100%;

  &__link {
    padding: 0 3px;
    margin: 0 10px;
    white-space: nowrap;
    display: flex;
    position: relative;
    cursor: pointer;
    height: 100%;
    align-items: center;

    @include t7;
  }

  .desktop-element__link.router-link-active {
    &::before {
      content: "";
      display: block;
      width: 100%;
      border-bottom: 4px solid $c-tim-accent;
      bottom: 0;
      left: 0;
      position: absolute;
    }
  }

  &__menu {
    z-index: 250;
    width: 100%;

    .menu__list {
      padding: 0;
    }

    .menu__link {
      padding: 14px 24px;
      width: 100%;
      display: flex;

      @include t10;
    }
  }
}

</style>

import GlobalModal from './components/modal/GlobalModal.vue';
import GlobalCard from './components/GlobalCard.vue';
import GlobalDropdown from './components/GlobalDropdown.vue';
import GlobalLink from './components/GlobalLink.vue';
import GlobalLoader from './components/GlobalLoader.vue';
import GlobalStatusIndicator from './components/GlobalStatusIndicator.vue';
import GlobalDivider from './components/GlobalDivider.vue';
import GlobalListElement from './components/GlobalListElement.vue';
import GlobalNotification from './components/GlobalNotification.vue';
import GlobalList from './components/GlobalList.vue';
import GlobalFileDownload from './components/GlobalFileDownload.vue';
import GlobalButton from './components/buttons/GlobalButton.vue';
import GlobalLinkButton from './components/buttons/GlobalLinkButton.vue';
import GlobalOptionsButton from './components/buttons/GlobalOptionsButton.vue';
import GlobalIcon from './components/GlobalIcon.vue';
import GlobalFeatureFlag from './components/GlobalFeatureFlag.vue';
import GlobalFileImage from './components/GlobalFileImage.vue';

export { default as UiBaseModalContainer } from './components/modal/ModalContainer.vue';
export { default as UiBaseTemporaryNotification } from './components/TemporaryNotification.vue';

// ENUMS
export {
  BASE_STATUS_COLOR,
  BASE_NOTIFICATION_TYPES,
  BASE_FILE_DOWNLOAD_NAME,
  FEATURE_FLAGS,
} from './model';

// BASE TYPES
export type {
  GlobalDropdownPlacement,
  FormatedDate,
  FormatedDateWithTime,
  FormatedPrice,
} from './model';

export { toDateFormat, toDateFormatWithTime, toPriceFormat } from './helpers';

export { useModal } from './composables/useModal';
export { useFileDownload } from './composables/useFileDownload';
export { default as useMobile } from './composables/useMobile';
export { useFeatureFlag } from './composables/useFeatureFlag';

export const globalComponents = {
  GlobalFileImage,
  GlobalModal,
  GlobalButton,
  GlobalCard,
  GlobalDropdown,
  GlobalLink,
  GlobalLinkButton,
  GlobalLoader,
  GlobalStatusIndicator,
  GlobalDivider,
  GlobalListElement,
  GlobalNotification,
  GlobalList,
  GlobalFileDownload,
  GlobalOptionsButton,
  GlobalIcon,
  GlobalFeatureFlag,
};

export const initPackage = (app: App<unknown>) => {
  // global components
  Object.keys(globalComponents).forEach((key) => {
    app.component(key, globalComponents[key]);
  });
};

<template>
  <GlobalModal
    :model-value="true"
    :header="$t('feedback.header')"
    :vertical-padding="8"
    @update:model-value="hideModal"
  >
    <p class="text">
      {{ $t('feedback.paragraph') }}
    </p>
    <Form ref="form">
      <UiLayoutGridBox>
        <div class="form-group">
          <BaseTextInput
            id="email"
            v-model="email"
            :label="$t('email')"
            name="email"
            rules="requiredRule|maxLength:255"
            class="form-group__item"
          />
          <BaseSelect
            id="reportType"
            v-model="reportType"
            name="reportType"
            :label="$t('feedback.reportType')"
            :item-list="reportTypeList"
            rules="requiredRule"
            class="form-group__item"
          />
        </div>
        <BaseFileButton
          full-width
          :text="$t('feedback.fileButton')"
          :clear-after-upload="false"
          @file-loaded="(event) => fileList = event"
        />
        <BaseTextarea
          id="reportContent"
          v-model="reportContent"
          name="reportContent"
          :max-length="1000"
          :max-height="100"
          rows="4"
          rules="requiredRule"
          :label="$t('feedback.reportContent')"
        />
      </UiLayoutGridBox>
    </Form>
    <template #actions="{ handleClose }">
      <div class="actions">
        <BaseReadMore class="text read-more">
          <span>{{ $t('feedback.policy.short', { email: 'odo@tim.pl' }) }}</span>
          <template #expanded>
            <span>{{ $t('feedback.policy.full') }}</span>
            <a
              :href="FILE_LINKS.RULES_PRIVACY"
              target="_blank"
              class="label-link"
            >{{ $t('rulesPrivacy') }}</a>.
          </template>
        </BaseReadMore>

        <div class="actions__buttons">
          <GlobalButton
            class="button__cancel"
            outlined
            no-border
            full-width
            :disabled="isLoading"
            @click="handleClose"
          >
            {{ $t('cancel') }}
          </GlobalButton>

          <GlobalButton
            full-width
            type="submit"
            :loading="isLoading"
            @click="handleConfirm"
          >
            {{ $t('feedback.confirm') }}
          </GlobalButton>
        </div>
      </div>
    </template>
  </GlobalModal>
</template>

<script lang="ts" setup>
import BaseFileButton from '@ui/BaseFileButton.vue';
import { useModal } from '@ui-base';
import { Form } from 'vee-validate';
import { useRequest } from '@request';
import { FILE_LINKS } from '@enums/global.enums';
import BaseReadMore from '@ui/BaseReadMore.vue';

import { sendFeedbackReport, getUserData } from '../service';
import { BaseTextInput, BaseSelect, BaseTextarea } from '@ui-form';
import { UiLayoutGridBox } from '@ui-layout';

const { hideModal } = useModal();

const { t } = useI18n();
const { callRequest } = useRequest();

// Report types used in select
const reportTypeList = [
  t('feedback.reportTypeList.0'),
  t('feedback.reportTypeList.1'),
  t('feedback.reportTypeList.2'),
].map((value) => ({ text: value, id: value }));

// Form data
const email = ref('');
const reportType = ref('');
const reportContent = ref('');
const fileList = ref<FileList>(null);

const isLoading = ref(false);

// handle report sending
const form = ref();
const handleConfirm = async () => {
  const { valid } = await form.value.validate();

  if (!valid) {
    return;
  }

  isLoading.value = true;
  const { hasError } = await callRequest(
    sendFeedbackReport({
      email: email.value,
      reportType: reportType.value,
      reportContent: reportContent.value,
      ...(fileList.value?.length ? { fileList: fileList.value[0] } : {}),
    }),
    {
      successMessage: 'Wysłane',
    },
  );

  if (!hasError) {
    hideModal();
  }

  isLoading.value = false;
};

// set user email
const setEmail = async () => {
  isLoading.value = true;
  const { data } = await callRequest(getUserData());
  email.value = data.email || '';
  isLoading.value = false;
};
setEmail();
</script>
<style lang="scss" scoped>
.actions {
  display: flex;
  flex-direction: column;

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    &:last-child {
      margin-right: 8px;
    }
  }
}

.button {
  &__cancel {
    margin-right: 8px;
  }
}

.form-group {
  display: flex;
  align-items: center;

  &__item {
    width: 50%;

    &:first-child {
      margin-right: 8px;
    }
  }
}

.text {
  @include t9;
}

.read-more {
  max-height: 200px;
  overflow: auto;
}

.label-link {
  color: $c-tim-blue;
}
</style>

<template>
  <div class="line-decorator">
    <slot />
  </div>
</template>
<script setup lang="ts">
type Props = {
  marginTop?: number | string
  marginBottom?: number | string
}
const props = withDefaults(defineProps<Props>(), {
  marginTop: 0,
  marginBottom: 0,
});

const normalizedMarginTop = computed(() => `${props.marginTop}px`);
const normalizedMarginBottom = computed(() => `${props.marginBottom}px`);
</script>
<style lang="scss" scoped>
.line-decorator {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  margin-top: v-bind('normalizedMarginTop');
  margin-bottom: v-bind('normalizedMarginBottom');
}
</style>

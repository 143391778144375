<template>
  <div
    data-t="file-display-image"
    class="file-display-image"
    v-bind="$attrs"
    @click.stop="handleFullScreen"
  >
    <GlobalFileImage
      class="image"
      :format="file.type"
      method="get"
      :link="file.link"
      :name="file.name"
    />
  </div>
  <FileDisplayImageFullScreen
    v-model="isFullScreen"
    :file="file"
    :user-full-name="userFullName"
    :created-at="createdAt"
  />
</template>
<script lang="ts" setup>
import { FileItem, MessageItem } from '../../../model';
import FileDisplayImageFullScreen from './FileDisplayImageFullScreen.vue';

type Props = {
  file: FileItem
  userFullName: MessageItem['user']['fullName']
  createdAt: MessageItem['createdAt']
}
defineProps<Props>();

const isFullScreen = ref(false);
const handleFullScreen = () => {
  isFullScreen.value = true;
};
</script>
<style lang="scss" scoped>
.file-display-image {
  width: 100%;
  height: 200px;
  border-radius: $base-radius;
  border: 1px solid $c-tim-gray-light;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 10px;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2), transparent);
    transition: width $base-transition;
  }

  &:hover {
    &::before {
      width: 20px;
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>

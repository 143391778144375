import CommonConfirmationModal from '../components/modal/CommonConfirmationModal.vue';
import { CommonConfirmationModalProps } from '../model';

const modalComponent = shallowRef<Component>(null);
const modalProps = ref(null);

export const useModal = () => {
  const showModal = (component: Component, props?: Record<string, unknown>) => {
    modalComponent.value = component;
    modalProps.value = props;
  };

  const hideModal = () => {
    modalComponent.value = null;
    modalProps.value = null;
  };

  const showConfirmationModal = (payload: CommonConfirmationModalProps) => {
    showModal(
      CommonConfirmationModal,
      payload,
    );
  };

  return {
    modalComponent,
    modalProps,
    showModal,
    hideModal,
    showConfirmationModal,
  };
};

<template>
  <Form ref="form">
    <UiLayoutGridBox>
      <BaseTextInput
        id="warehouseName"
        v-model="warehouseAddressForm.warehouseName"
        :label="$t('warehouseAddressSupplier.form.warehouseName')"
        name="warehouseName"
        rules="requiredRule|minLength:3"
        :is-disabled="isLoading"
      />
      <div class="form-group">
        <BaseTextInput
          id="street"
          v-model="warehouseAddressForm.warehouseAddress.street"
          :label="$t('warehouseAddressSupplier.form.street')"
          name="street"
          rules="requiredRule|minLength:3"
          :is-disabled="isLoading"
          class="form-group__item"
        />
        <BaseTextInput
          id="buildingNumber"
          v-model="warehouseAddressForm.warehouseAddress.buildingNumber"
          :label="$t('warehouseAddressSupplier.form.buildingNumber')"
          name="buildingNumber"
          rules="requiredRule"
          :is-disabled="isLoading"
          class="form-group__item"
        />
      </div>
      <div class="form-group">
        <BaseTextInput
          id="city"
          v-model="warehouseAddressForm.warehouseAddress.city"
          :label="$t('warehouseAddressSupplier.form.city')"
          name="city"
          rules="requiredRule|minLength:3"
          :is-disabled="isLoading"
          class="form-group__item"
        />
        <BaseTextInput
          id="postCode"
          v-model="warehouseAddressForm.warehouseAddress.postCode"
          :label="$t('warehouseAddressSupplier.form.postCode')"
          name="postCode"
          rules="requiredRule|postCodeRule|minLength:3"
          :is-disabled="isLoading"
          class="form-group__item"
        />
      </div>
      <BaseTextarea
        id="collectRestrictions"
        v-model="warehouseAddressForm.collectRestrictions"
        :label="$t('warehouseAddressSupplier.form.collectRestrictions')"
        name="collectRestrictions"
        :max-length="70"
        :max-height="60"
        rows="2"
        :is-disabled="isLoading"
      >
        <template #after-input>
          <BaseTooltip :max-width="400">
            <GlobalIcon name="info" />
            <template #tooltip>
              {{ $t('warehouseAddressSupplier.collectRestrictionsTooltip') }}
            </template>
          </BaseTooltip>
        </template>
      </BaseTextarea>
    </UiLayoutGridBox>
  </Form>
</template>
  
<script lang="ts" setup>
import { Form } from 'vee-validate';
import { WarehouseAddressForm } from '../model';
import { COUNTRY } from '@enums/global.enums';
import BaseTooltip from '@ui/BaseTooltip.vue';
import { BaseTextarea, BaseTextInput } from '@ui-form';
import { UiLayoutGridBox } from '@ui-layout';
  
const props = defineProps<{
  isLoading: boolean,
  warehouseAddress?: WarehouseAddressForm
}>();

// Form data
const warehouseAddressForm = ref<WarehouseAddressForm>({
  warehouseName: '',
  warehouseAddress: {
    street: '',
    buildingNumber: '',
    city: '',
    postCode: '',
    country: COUNTRY.POLAND,
  },
  collectRestrictions: '',
});

// update on change
watchEffect(() => {
  if (!props.warehouseAddress) {
    return;
  }

  const { warehouseName, collectRestrictions, warehouseAddress } = props.warehouseAddress;
  warehouseAddressForm.value.warehouseName = warehouseName;
  warehouseAddressForm.value.collectRestrictions = collectRestrictions || '';
  warehouseAddressForm.value.warehouseAddress = { ...warehouseAddressForm.value.warehouseAddress, ...warehouseAddress };
});

const form = ref();
defineExpose({
  validate: () => form.value.validate(),
  getAddress: () => warehouseAddressForm.value,
});
</script>
<style lang="scss" scoped>
.form-group {
  display: flex;
  align-items: center;

  &__item {
    width: 50%;
    
    &:first-child {
      margin-right: 8px;
    }
  }
}
</style>

<template>
  <div
    class="base-container"
    :class="{ 'is-loading': loading }"
  >
    <div
      class="base-container__options"
      :class="{ 'base-container__options--hide': hideSearch && hideActionsHeader }"
    >
      <div class="base-container__search-panel">
        <template v-if="!hideSearch">
          <div class="base-container__search">
            <BaseTextInput
              id="table-search"
              v-model="searchPhrase"
              name="table-search"
              :placeholder="searchPlaceholder"
              is-dense
              @keydown.enter="$emit('search')"
            >
              <template #before-input>
                <GlobalIcon
                  name="search"
                  class="icon-search"
                  @click="$emit('search')"
                />
              </template>
              <template #after-input>
                <GlobalIcon
                  v-if="String(searchPhrase).length"
                  name="close"
                  class="icon-search"
                  @click="handleClear"
                />
              </template>
            </BaseTextInput>
          </div>
          <div class="base-container__filters">
            <slot name="filters" />
          </div>
        </template>
        <div class="base-container__button-container">
          <slot name="header-button" />
        </div>
      </div>
      <div
        v-if="!hideActionsHeader"
        class="base-container__actions-panel"
      >
        <div
          v-if="$slots.actions"
          class="base-container__actions"
        >
          <slot name="actions" />
        </div>
        <div
          v-if="pagination"
          class="base-container__pagination-text"
        >
          {{ pagination.paginationText }}
        </div>
      </div>
    </div>
    <div class="base-container__content-container">
      <div class="content-container__inner">
        <RowItem
          :background-color="headerColor"
          row-id="thead"
        >
          <CellItem
            v-if="!hideAllSelector"
            col-id="checkbox"
          >
            <BaseCheckbox
              id="all-rows"
              v-model="checkboxValue"
              class="base-container__checkbox"
              name="all-rows"
            />
          </CellItem>
          <slot name="table-header" />
        </RowItem>
        <div
          v-if="isEmpty && !allowEmptyBody"
          class="base-container__loading-body"
        >
          <GlobalLoader v-if="loading" />
          <span v-else>{{ $t('emptyRecords') }}</span>
        </div>
        <slot
          v-else
          name="table-body"
        />
      </div>
    </div>
    <div
      v-if="pagination"
      class="base-container__footer"
    >
      <ResultsAmount
        :amount="pagination.perPage"
        @amount-change="$emit('amount-change', $event)"
      />
      <PaginationElement
        v-bind="pagination"
        @page-change="$emit('page-change', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseCheckbox from '@ui/BaseCheckbox.vue';
import ResultsAmount from './ResultsAmount.vue';
import { BaseTextInput } from '@ui-form';
import PaginationElement from './PaginationElement.vue';
import RowItem from './RowItem.vue';
import CellItem from './CellItem.vue';

interface PaginationData {
  page: number
  perPage: number
  total: number,
  paginationText: string
}

interface Props {
  isAllSelected?: boolean
  pagination?: PaginationData
  loading?: boolean
  search?: string
  searchPlaceholder?: string
  hideSearch?: boolean,
  hideAllSelector?: boolean,
  headerColor?: string,
  allowEmptyBody?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  pagination: null,
  isAllSelected: false,
  loading: false,
  search: '',
  searchPlaceholder: '',
  hideSearch: false,
  hideAllSelector: false,
  headerColor: '#EEEEEE',
  allowEmptyBody: false,
});

const $emit = defineEmits(['toggle-all', 'page-change', 'update:search', 'search', 'amount-change']);

const searchPhrase = computed({
  get() {
    return props.search;
  },
  set(value) {
    $emit('update:search', value);
  },
});

const checkboxValue = computed({
  get() {
    return props.isAllSelected;
  },
  set(value) {
    $emit('toggle-all', value);
  },
});

const isEmpty = computed(() => !props.pagination ? false : !props.pagination?.total);

const handleClear = () => {
  searchPhrase.value = '';
  $emit('search');
};

const slots = useSlots();
const hideActionsHeader = computed(() => !slots.actions && !props.pagination);
</script>

<style lang="scss" scoped>

:deep(.checkbox-container) {
  margin: 0;
}

:deep(.checkbox-container__checkbox) {
  margin: 0;
  border-color: $c-tim-gray-dark;
}

.base-container {
  background-color: $c-white;

  // TODO: fix table
  // margin: 0 -16px;

  &.is-loading {
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba($c-white, 0.6);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
    }
  }

  &__options {
    height: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include to-xl {
      height: auto;
    }

    &--hide {
      display: none;
    }
  }

  &__search-panel {
    display: flex;
    align-items: center;

    @include to-xl {
      margin-bottom: 16px;
      flex-direction: column;
    }
  }

  &__button-container {
    display: flex;
    margin-left: auto;
    justify-content: center;

    :deep(.base-btn) {
      margin-left: 12px;
    }

    @include to-sm {
      width: 100%;
      flex-wrap: wrap;

      :deep(.base-btn:nth-child(1)) {
        margin-left: 0;
      }

      :deep(.base-btn) {
        width: 100%;
        margin: 8px 0;
      }
    }
  }

  &__search {
    width: 100%;
    max-width: 240px;

    @include to-sm {
      display: flex;
      align-items: center;
      max-width: 100%;
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    margin-left: 16px;

    @include to-xl {
      margin-left: 0;
      margin-bottom: 16px;
      justify-content: center;
    }

    @include to-sm {
      align-self: flex-start;
      flex-direction: column;
    }

    :deep(> div) {
      @include to-xl {
        margin-top: 16px;
      }
    }
  }

  &__actions-panel {
    display: flex;
    align-items: center;

    @include to-sm {
      flex-wrap: wrap;
    }
  }

  &__checkbox {
    flex-basis: 58px;
  }

  &__loading-body {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $c-tim-gray-light;
  }

  &__actions {
    flex-grow: 1;

    @include to-sm {
      margin: 8px 0 0;
    }
  }

  &__pagination-text {
    color: $c-tim-gray-dark;

    @include to-sm {
      margin: 8px 0 0;
      white-space: nowrap;
    }
  }

  &__content-container {
    overflow-x: auto;

    .content-container__inner {
      min-width: 1100px;
    }
  }

  &__footer {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
  }
}

.icon-search {
  color: $c-tim-gray-dark;
}

::v-deep(.checkbox-container) {
  margin-left: 3px;
}
</style>

<template>
  <div class="base-options-button">
    <button
      ref="leftButtonRef"
      data-t="base-options-button:button"
      class="left-button"
      :class="buttonClasses"
      :disabled="isButtonDisabled"
      @click="$emit('click:button')"
    >
      <GlobalLoader
        v-if="loading"
        class="loader"
        size="24px"
      />
      <span :class="{ 'hide-content': loading }"><slot /></span>
    </button>
    <GlobalDropdown
      :offset="[-leftButtonWidth, 4]"
      is-click-only
      placement="bottom-start"
    >
      <template #trigger="{ isOpen }">
        <button
          class="right-button"
          :class="buttonClasses"
          :disabled="isButtonDisabled"
        >
          <GlobalIcon
            name="arrow_drop_down"
            class="right-button__icon"
            :class="{ 'icon-rotate': isOpen }"
          />
        </button>
      </template>
      <GlobalList
        class="option-list"
        overflow
        :max-width="LIST_WIDTH"
        :max-height="LIST_HEIGHT"
      >
        <template v-if="options.length">
          <GlobalListElement
            v-for="option in options"
            :key="option.id"
            with-subheader
            :style="{ width: LIST_WIDTH }"
            :icon="option.icon"
            @click:item="$emit('click:option', option)"
            @click:icon="$emit('click:icon', option)"
          >
            {{ option.text }}
          </GlobalListElement>
        </template>
        <GlobalListElement
          v-else
          active
          with-subheader
          class="option-item"
        >
          Brak pozycji na liście
        </GlobalListElement>
      </GlobalList>
    </GlobalDropdown>
  </div>
</template>

<script lang="ts" setup>
type OptionItem = {
  id: string
  text: string
  icon?: string
}

interface Props {
  loading?: boolean
  disabled?: boolean
  options: OptionItem[],
}
const props = withDefaults(defineProps<Props>(), {
  loading: false,
  disabled: false,
});
const $emit = defineEmits<{
  (event: 'click:option', value: OptionItem): void
  (event: 'click:icon', value: OptionItem): void
  (event: 'click:button'): void
}>();

const LIST_WIDTH = '280px';
const LIST_HEIGHT = '285px';

const buttonClasses = computed(() => {
  return {
    'is-disabled': isButtonDisabled.value,
  };
});
const isButtonDisabled = computed(() => {
  return props.disabled || props.loading;
});

const leftButtonRef = ref<HTMLElement | null>(null);
const leftButtonWidth = computed(() => leftButtonRef.value?.offsetWidth || 0);
</script>

<style lang="scss" scoped>
.base-options-button {
  display: inline-flex;
  align-items: center;
}

.left-button,
.right-button {
  transition: all $base-transition;
  border: none;
  height: 48px;
  background-color: $c-tim-blue;
  color: $c-white;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  user-select: none;
  letter-spacing: 1.25px;

  &:hover {
    background-color: $c-tim-blue-dark;
  }

  &.is-disabled {
    background-color: $c-tim-gray;
    cursor: not-allowed;

    &:hover {
      background-color: $c-tim-gray;
    }
  }
}

.left-button {
  padding: 12px 24px;
  border-radius: $base-radius 0 0 $base-radius;
  border-right: 1px solid $c-tim-blue-light;
  position: relative;

  .hide-content {
    visibility: hidden;
    opacity: 0;
  }

  .loader {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.right-button {
  padding: 12px 5px;
  border-radius: 0 $base-radius $base-radius 0;

  &__icon {
    transition: all $base-transition;

    &.icon-rotate {
      transform: rotate(180deg);
    }
  }
}

.option-list {
  padding: 0;
  border-radius: $base-radius;
  border: 1px solid $c-tim-gray-light;
}
</style>

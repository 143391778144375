import { ADMIN_ROUTER_LINKS } from '@enums/admin.enums';

export const createCommunicationPanelAdminRoute = () =>  ({
  path: ADMIN_ROUTER_LINKS.ADMIN_PANEL_COMMUNICATION,
  name: 'AppAdminCommunication',
  component: () => import('./components/admin/AppAdminCommunication.vue'),
  meta: {
    pageTitle: 'Panel komunikacji',
  },
});

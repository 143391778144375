import { AxiosError, AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import cloneDeep from 'lodash/cloneDeep';
import { BASE_NOTIFICATION_TYPES } from '@ui-base';
import useToast from '@composables/useToast';
import { MULTI_CULTI_TYPE } from '@enums/global.enums';
import { useRequest } from '@request';

// TODO: type should cast response as null when error occurs
export const requestCaller = async <T>(request: Promise<AxiosResponse<T>>, successMessage?: string, errorMessage?: string): Promise<[T | null, AxiosError | null, RawAxiosResponseHeaders | AxiosResponseHeaders | null]> => {
  const { getErrorMessage } = useRequest();
  const { openToast } = useToast();
  try {
    const { data, headers } = await request;
    if(successMessage && typeof successMessage === 'string') {
      openToast(BASE_NOTIFICATION_TYPES.SUCCESS, successMessage);
    }
    return [data, null, headers];
  } catch (error) {
    const { message: beMessage, skipNotification }  = getErrorMessage(error as AxiosError<null>);

    // Skip notification when BE wants to (then we will get `skipNotification: true` from BE)
    if (!skipNotification) {
      const message = typeof errorMessage === 'string' ? errorMessage : beMessage;
      openToast(BASE_NOTIFICATION_TYPES.ERROR, message);
    }

    return [null, (error as AxiosError), null];
  }
};

export const getMergedNullableObjects = <T>(obj1: unknown, obj2: unknown): T => {
  return cloneDeep({ ...omitBy(obj1, isNull), ...omitBy(obj2, isNull) }) as T;
};

export const fileSizeConverter = (fileSize: number) => {
  return `${(fileSize / Math.pow(1024, 2)).toFixed(2)} MB`;
};

export const scoringColorRange = (score: number | unknown): string => {
  if(typeof score !== 'number'){
    return '#212121';
  }

  if(score <= 2) {
    return '#d70000';
  }

  if(score === 3) {
    return '#fd0';
  }

  return '#4daf50';
};

export { cloneDeep as clone };

// TODO: replace with https://vueuse.org/core/useClipboard/#useclipboard
export const copyValueToClipboard = (copyText: string) => {
  if(navigator.clipboard && window.isSecureContext){
    navigator.clipboard.writeText(copyText);
  } else {
    const input = document.createElement('input');
    input.value = copyText;
    input.style.visibility = 'none';
    input.style.width = '0';
    input.style.height = '0';
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    input.remove();
  }
};

/*
 source: https://marcin.laber.pl/2014/09/odmiana-liczebnikow-w-javascript/
 usage:
 value - number to which the word version has to be fitted
 numerals - array of polish words describing ['0 items of given thing', '1 item of given thing', '2 items of given thing']
 wovalue - "without value" - if true, skips number in output and output only word
 */
export const returnProperUnit = (value: number, type: MULTI_CULTI_TYPE | string, wovalue?: boolean): string => {
  const t0 = value % 10;
  const t1 = value % 100;
  const vo = [];

  if (wovalue !== true) {
    vo.push(value);
  }

  if (!MULTI_CULTI_TYPE[type]) {
    vo.push(type);
    return vo.join(' ').toLowerCase();
  }

  const { t } = useI18n();
  const numerals = [t(`multiCulti.${type}.more`), t(`multiCulti.${type}.single`), t(`multiCulti.${type}.otherMore`)];

  if (value === 1 && numerals[1]) {
    vo.push(numerals[1]);
  } else if ((value === 0 || (t0 >= 0 && t0 <= 1) || (t0 >= 5 && t0 <= 9) || (t1 > 10 && t1 < 20)) && numerals[0]) {
    vo.push(numerals[0]);
  } else if ((t1 < 10 || t1 > 20) && t0 >= 2 && t0 <= 4 && numerals[2]) {
    vo.push(numerals[2]);
  }
  return vo.join(' ').toLowerCase();
};

export const querySelector = (query: string): HTMLElement | null => {
  try {
    return document.querySelector(query);
  } catch (err) {
    console.warn(err);
    return null;
  }
};

export const triggerScroll = (top = 0) => window.scrollTo({ top, left: 0, behavior: 'smooth' });

/**
 * Funkcja, która symuluje działanie setInterval, ale z obsługą asynchronicznych operacji.
 * Wywołuje podaną funkcję asynchroniczną co określony interwał czasu.
 * Czas do następnego wywołania zaczyna lecieć dopiero po zakończeniu poprzedniego wywołania.
 * 
 * @param {Function} fn - Asynchroniczna funkcja, która ma być wywoływana co określony interwał czasu.
 * @param {number} ms - Interwał czasu (w milisekundach) po którym funkcja ma być wywoływana.
 * @returns {Function} - Funkcja, która po wywołaniu anuluje interwał.
 */
export function setIntervalAsync(fn: () => Promise<void>, ms: number) {
  let timeoutId: ReturnType<typeof setTimeout> = null;

  const loop = () => {
    timeoutId = setTimeout(async () => {
      try {
        await fn();

        if (timeoutId === null) {
          return;
        }

        loop();
      } catch (error) {
        clearTimeout(timeoutId);
        throw error; // Rzucamy błąd ponownie, aby go obsłużyć na wyższym poziomie
      }
    }, ms);
  };

  loop();

  return () => {
    clearTimeout(timeoutId);
    timeoutId = null;
  };
}

export const onBeforeMountStory = (callback: (app: App) => void) => async ({ app }: { app: App }) => {
  app.runWithContext(async () => {
    callback(app);
  });
};

export const onMountedStory = (callback: (app: App) => void) => async ({ app }: { app: App }) => {
  app.runWithContext(async () => {
    // simulates onMounted
    setTimeout(() => callback(app), 0);
  });
};

export const safeJoin = (array: (string | number | null | undefined)[], separator = ' '): string => {
  return array.filter(Boolean).join(separator);
};

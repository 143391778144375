<template>
  <div>
    <FileListDecorator v-if="fileList.length">
      <FileDisplayNew
        v-for="(file, index) in fileList"
        :key="index"
        :file="file"
        class="file-add-box-file"
        @delete="deleteFile(index)"
      />
    </FileListDecorator>
    <div
      v-bind="$attrs"
      ref="fileAddBoxRef"
      class="file-add-box"
      :class="{ 'is-over': isOverDropZone, 'is-disabled': isDisabled }"
      @click="openFileDialog"
    >
      <GlobalIcon
        name="attach_file_add"
        is-symbol
        class="icon"
      />
      <span class="text">{{ isOverDropZone ? 'Upuść' : 'Przeciągnij i upuść plik lub wybierz z dysku' }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import FileDisplayNew from './FileDisplayNew.vue';
import FileListDecorator from '../../decorators/FileListDecorator.vue';
import { useFileList } from '../../../composables/common/useFileList';
import { ValidatedFile } from '../../../model/File';

type Props = {
  isDisabled?: boolean
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'update:file-list', fileList: ValidatedFile[]): void
}>();

const isDisabled = computed(() => props.isDisabled);
const fileAddBoxRef = ref<HTMLDivElement>();
const {
  fileList,
  clearFileList,
  deleteFile,
  openFileDialog,
  isOverDropZone,
} = useFileList({
  dropElement: fileAddBoxRef,
  isDisabled,
  onFileChange: (files) => $emit('update:file-list', files),
});

defineExpose({
  clearFileList,
});
</script>
<style lang="scss" scoped>
.file-add-box {
  display: grid;
  border: 1px solid $c-tim-gray-light;
  border-style: dashed;
  border-radius: $base-radius;
  background: $c-tim-blue-lightest;
  gap: 4px;
  place-items: center center;
  padding: 24px;
  transition: background-color $base-transition;
  cursor: pointer;

  &.is-over,
  &:hover {
    background: $c-tim-gray-lightest-alt;
    border-style: solid;
  }

  &.is-disabled {
    pointer-events: none;
  }
}

.icon, .text {
  color: $c-tim-blue;
}

.text {
  @include t8;

  text-transform: uppercase;
  text-align: center;
}
</style>

<template>
  <div class="breadcrumbs">
    <ul class="breadcrumbs__list">
      <li
        v-for="crumb in normalizedBreadcrumbs"
        :key="crumb.id"
        class="breadcrumbs__item"
      >
        <component
          :is="crumb.is"
          :to="crumb.to"
          class="breadcrumbs__crumb"
          data-t="breadcrumb-crumb"
        >
          {{ crumb.text }}
        </component>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
interface Breadcrumbs {
  id: string,
  text: string,
  to: string,
  isDynamic: boolean,
  withoutLink: boolean,
}

interface Props {
  breadcrumbs?: Breadcrumbs[]
}
const props = withDefaults(defineProps<Props>(), {
  breadcrumbs: () => [] as Breadcrumbs[],
});

const { t } = useI18n();

const isLastBreadcrumb = (index: number) => index === props.breadcrumbs.length - 1;
const getComponent = (crumb: Breadcrumbs, index: number) => (isLastBreadcrumb(index) || crumb.withoutLink) ? 'span' : 'GlobalLink';

const normalizedBreadcrumbs = computed(() => props.breadcrumbs.map((crumb, index) => ({
  id: crumb.id,
  is: getComponent(crumb, index),
  to: crumb.to,
  text: crumb.isDynamic ? crumb.text : t(crumb.text),
})));
</script>

<style lang="scss" scoped>

.breadcrumbs {
  &__list {
    display: flex;

    @include to-sm {
      flex-wrap: wrap;
    }
  }

  &__item {
    .breadcrumbs__crumb {
      @include t7;

      color: $text-color-primary;
      display: inline-block;
    }

    &::after {
      content: "";
      margin: 0 10px;
      width: 6px;
      height: 9px;
      display: inline-block;
      background-image: url("/src/assets/icons/icon-arrow.svg");
      background-size: contain;
    }

    &:last-child {
      &::after {
        display: none;
      }

      .breadcrumbs__crumb {
        color: $c-tim-gray;
        font-weight: 400;
        letter-spacing: 0.25px;
      }
    }
  }
}
</style>

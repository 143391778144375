<template>
  <div
    class="base-divider"
    :class="{ 'base-divider--vertical': vertical }"
  />
</template>

<script setup lang="ts">
interface Props {
  margin?: string,
  vertical?: boolean,
  height?: string
}

const props = withDefaults(defineProps<Props>(), {
  margin: '16px 0',
  vertical: false,
  height: '25px',
});
</script>

<style lang="scss" scoped>

.base-divider {
  margin: v-bind("props.margin");
  height: 1px;
  background-color: $c-tim-gray-light;

  &--vertical {
    height: v-bind("props.height");
    width: 1px;
  }
}
</style>

const currentBreadcrumb = ref('');

const useBreadcrumbs = () => {
  const $route = useRoute();

  const breadcrumbsList = computed(() => {
    const items = $route.matched.map(matchedRoute => ({
      id: matchedRoute.meta.pageTitle,
      to: matchedRoute.path,
      text: matchedRoute.meta.breadcrumbsTitle || matchedRoute.meta.pageTitle,
      isDynamic: false,
      withoutLink: !!matchedRoute.redirect,
    }))
      .filter(crumb => crumb.text);

    if (currentBreadcrumb.value) {
      items[items.length - 1].text = currentBreadcrumb.value;
      items[items.length - 1].isDynamic = true;
    }

    return items;
  });

  const showBreadcrumbs = computed(() => {
    return !$route.meta.hideBreadcrumbs && breadcrumbsList.value.length > 1;
  });

  const currentPageName: ComputedRef<string> = computed(() => $route.meta.pageTitle || $route.meta.breadcrumbsTitle || '');

  const updateCurrentBreadcrumbBasedOn = (reactiveValue: Ref<string>) => {
    watchEffect(() => {
      currentBreadcrumb.value = reactiveValue.value;
    });
    
    onBeforeUnmount(() => {
      currentBreadcrumb.value = '';
    });
  };

  return {
    showBreadcrumbs,
    breadcrumbsList,
    currentPageName,
    updateCurrentBreadcrumbBasedOn,
  };
};

export default useBreadcrumbs;

<template>
  <div class="pagination">
    <GlobalIcon
      name="chevron_left"
      class="pagination__icon pagination__icon--prev"
      :class="{'is-disabled': isFirstPage}"
      @click="changePage(page - 1)"
    />
    <BaseNumberInput
      id="page"
      v-model="localPage"
      class="pagination__input"
      name="page"
      is-dense
      @blur="() => handleBlur(localPage)"
    />
    <span class="pagination__page">{{ $t('from') }}</span>
    <span class="pagination__page">{{ totalPages }}</span>
    <GlobalIcon
      name="chevron_right"
      class="pagination__icon"
      :class="{'is-disabled': isLastPage}"
      @click="changePage(page + 1)"
    />
  </div>
</template>

<script setup lang="ts">
import { BaseNumberInput } from '@ui-form';

interface PaginationElementProps {
  page: number
  perPage: number
  total: number
}
const props = defineProps<PaginationElementProps>();
const $emit = defineEmits(['page-change']);

const firstElement = computed(() => {
  return ((props.page - 1) * props.perPage) + 1;
});

const isFirstPage = computed(() => {
  return props.page === 1;
});

const isLastPage = computed(() => {
  return props.page * props.perPage >= props.total;
});

const localPage = ref(props.page);
watch(() => props.page, (value) => {
  localPage.value = value;
});
let debounce = null;
watch(localPage, (value) => {
  if(value && value !== props.page){
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      changePage(value);
    }, 500);
  }
});
const totalPages = computed(() => Math.ceil(props.total / props.perPage));
const changePage = (page: number) => {
  if(page > 0 && page <= totalPages.value) {
    $emit('page-change', page);
  }
};
const handleBlur = (value: number) => localPage.value = Math.max(1, Math.min(totalPages.value, value));

watch(() => props.total, () => {
  if(!isFirstPage.value && firstElement.value > props.total) {
    $emit('page-change', 1);
  }
});

onUnmounted(() => {
  clearTimeout(debounce);
});
</script>

<style lang="scss" scoped>

.pagination {
  @include t9;

  color: $c-tim-gray-dark;
  display: flex;
  align-items: center;

  &__page {
    margin-right: 8px;

    @include base-font;
  }

  &__input {
    width: 40px;
    height: 40px;
    margin-right: 8px;

    :deep(input) {
      text-align: center;
      font-size: 14px;
    }
  }

  &__icon {
    color: $c-tim-gray-dark;
    cursor: pointer;

    &--prev {
      margin-right: 9px;
    }

    &.is-disabled {
      color: $c-tim-gray;
      cursor: auto;
    }
  }
}
</style>

import StartImpersonateButton from './components/StartImpersonateButton.vue';
import StopImpersonateButton from './components/StopImpersonateButton.vue';
import localMessages from './local-messages';
import useImpersonateSession from './composables/useImpersonateSession';

export const useImpersonate = () => {
  const { stopImpersonate } = useImpersonateSession();

  return {
    stopImpersonate,
  };
};

export const initPackage = () => {
  const { checkImpersonateSession } = useImpersonateSession();
  checkImpersonateSession();

  const { mergeLocaleMessage } = useI18n();
  mergeLocaleMessage('pl', localMessages);
};

export const components = {
  StartImpersonateButton,
  StopImpersonateButton,
};

import useToast from '@composables/useToast';
import { getErrorMessage } from './helpers';
import { createCallRequest } from './callRequest';
import { abortToken } from './abortToken';

export const initPackage = () => {};

export function useRequest () {
  const { openToast } = useToast();
  const callRequest = createCallRequest({ openToast });

  return {
    getErrorMessage,
    callRequest,
    abortToken,
  };
}

export { ERROR_AUTH_CODES } from './model';

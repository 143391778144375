const useMobile = () => {
  // NOTE: same width like in mediaqueries mixins
  const BREAKPOINT_SM = 576 as const;
  const isMobile = ref(window.innerWidth < BREAKPOINT_SM);

  onMounted(() => {
    window.addEventListener('resize', onResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', onResize);
  });

  const onResize = () => {
    isMobile.value =  window.innerWidth < BREAKPOINT_SM;
  };

  return {
    isMobile,
  };
};

export default useMobile;

<template>
  <GlobalButton
    dense
    outlined
    no-border
    :loading="loading"
    @click.prevent="handleAddressCreate"
  >
    {{ $t('add') }}
  </GlobalButton>
</template>

<script setup lang="ts">
import { useModal } from '@ui-base';
import AddAddressModal from './AddAddressModal.vue';

interface Props {
  supplierId: string,
  loading: boolean
}

const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'address-created'): void
}>();

const { showModal } = useModal();

function handleAddressCreate() {
  showModal(
    AddAddressModal,
    {
      supplierId: props.supplierId,
      refreshData: () => $emit('address-created'),
    },
  );
}
</script>

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  class: "base-loader",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "16",
      cy: "16",
      r: "15",
      stroke: "#F4FAFE",
      "stroke-width": "2"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "16",
      cy: "16",
      r: "15",
      stroke: "#1D9BF0",
      "stroke-dasharray": "94",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }, null, -1)
  ])))
}
export default { render: render }
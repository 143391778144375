import { ROUTER_LINKS } from '@enums/global.enums';

export const createInvoiceDetailsRoute = () =>  ({
  path: `${ROUTER_LINKS.SALES_INVOICES}/:id`,
  name: 'AppSalesInvoice',
  component: () => import('./components/PageDetailsView.vue'),
  meta: {
    pageTitle: 'menu.submitInvoice',
  },
});

import FeedbackReportModal from './components/FeedbackReportModal.vue';
import { useModal } from '@ui-base';
import localMessages from './local-messages';

export const useFeedback = () => {
  const { showModal } = useModal();

  return {
    showReportModal: () => showModal(FeedbackReportModal),
  };
};

export const initPackage = () => {
  const { mergeLocaleMessage } = useI18n();
  mergeLocaleMessage('pl', localMessages);
};

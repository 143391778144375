<template>
  <div
    class="status"
    :class="statusClass"
  >
    <div class="status__text">
      <span>
        {{ label }}
      </span>
      <span
        v-if="withProgress"
        class="status__percent"
      >
        {{ `${progressPercent ? `${progressPercent} %` : ''}` }}
      </span>
    </div>
    <div class="status__progress" />
  </div>
</template>

<script setup lang="ts">

interface Props {
  label: string,
  withProgress?: boolean,
  progressPercent?: number,
  color?: 'red' | 'blue' | 'green'
}

const props = withDefaults(defineProps<Props>(), {
  progressPercent: 100,
  color: undefined,
});

const statusClass = computed(() => {
  return `status--${props.color || (props.progressPercent === 100 ? 'green' : 'blue')}`;
});

const progressWidth = computed(() => props.withProgress ? `${props.progressPercent}%` : '100%');
</script>

<style lang="scss" scoped>

.status {
  &__status__text {
    @include t9;

    line-height: 12px;
  }

  &__text {
    display: flex;
    justify-content: space-between;
  }

  &__progress {
    width: 100%;
    height: 4px;
    margin: 4px 0 0;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      width: v-bind("progressWidth");
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &--endless {
      &::after {
        width: 50%;
        border-radius: 50%;
        transform: translateX(-50%);
        animation: endless-progress 1s ease-in-out infinite;
      }
    }
  }

  &--blue {
    .status__progress {
      background-color: $c-tim-blue-light;

      &::after {
        background-color: $c-tim-blue;
      }
    }
  }

  &--green {
    .status__progress {
      background-color: $c-tim-green-light;

      &::after {
        background-color: $c-tim-green;
      }
    }
  }

  &--red {
    .status__progress {
      background-color: $c-tim-red-light;

      &::after {
        background-color: $c-tim-red;
      }
    }
  }
}
</style>

<template>
  <BaseConfirmationModal
    :model-value="true"
    :is-loading="isLoading"
    :type="type"
    @confirm="handleConfirm"
    @update:model-value="hideModal"
  >
    <template #header>
      Potwierdź działanie
    </template>
    {{ content }}
  </BaseConfirmationModal>
</template>
<script setup lang="ts">
import { useModal } from '@ui-base';
import BaseConfirmationModal from '@ui/BaseConfirmationModal.vue';
import { CommonConfirmationModalProps } from '../../model';
	
const props = defineProps<CommonConfirmationModalProps>();
	
const { hideModal } = useModal();

const isLoading = ref(false);
const handleConfirm = async () => {
  isLoading.value = true;
  await props.callback();
  isLoading.value = false;
  hideModal();
};
</script>
  

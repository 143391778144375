import baseService, { toFormData } from '@services/baseService';
import { AxiosResponse } from 'axios';

export const sendFeedbackReport = (payload: {
  email: string;
  reportType: string;
  reportContent: string;
  file?: File
}) => {
  return baseService.post('/redmine/report_feedback', toFormData(payload));
};

export const getUserData = async (): Promise<AxiosResponse<{ email: string }>> => {
  return await baseService.get(`/users/${localStorage.getItem('userId')}`);
};


export enum ERROR_AUTH_CODES {
  INVALID_LOGIN_DATA = 4012,
  EXPIRED_TOKEN = 4013,
  MISSING_TOKEN = 4011,
  INVALID_TOKEN = 4031,
}
export type ErrorInfo = Partial<{
  violations: { message?: string }[]
  message: string;
  skipNotification: boolean;
}>

export const ABORT_TOKEN_ERROR = 'ABORT_TOKEN_ERROR';

import { Placement } from '@popperjs/core';

export enum BASE_STATUS_COLOR {
  BLUE = 'blue',
  GREEN = 'green',
  LIGHT_GREEN = 'light-green',
  RED = 'red',
  ORANGE = 'orange',
  GRAY = 'gray',
  PINK = 'pink',
}

export const enum BASE_NOTIFICATION_TYPES {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export enum BASE_FILE_DOWNLOAD_NAME {
  CSV = 'text/csv',
  PDF = 'application/pdf',
  XML = 'application/xml',
}

export type FileDownloadProps = {
  link: string,
  name: string,
  format: BASE_FILE_DOWNLOAD_NAME,
  method: 'get' | 'post',
  payload?: Record<string, unknown>
}

export type CommonConfirmationModalProps = {
  content: string,
  callback: () => void,
  type?: 'confirm' | 'yes-no',
}

export type GlobalDropdownPlacement = Placement

// YYYY-MM-DD
export type FormatedDate = Distinct<string, 'FormatedDate'>
// YYYY-MM-DD HH:MM:SS
export type FormatedDateWithTime = Distinct<string, 'FormatedDateWithTime'>
export type FormatedPrice = Distinct<string, 'FormatedPrice'>

export enum FEATURE_FLAGS {
  CHANGE_ME = 'CHANGE_ME',
}

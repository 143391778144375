<template>
  <GlobalModal
    :model-value="true"
    :header="$t('warehouseAddressSupplier.addFormHeader')"
    :is-loading="isLoading"
    :vertical-padding="8"
    @save="handleSave"
    @close="hideModal"
  >
    <AddressModalForm
      ref="form"
      :is-loading="isLoading"
    />
  </GlobalModal>
</template>

<script lang="ts" setup>
import AddressModalForm from './AddressModalForm.vue';
import { useModal } from '@ui-base';
import { addAddress } from '../service';

const { hideModal } = useModal();

const props = defineProps<{
  supplierId: string,
  refreshData: () => Promise<void>
}>();

const isLoading = ref(false);
const form = ref<InstanceType<typeof AddressModalForm>>();
const handleSave = async () => {
  const { valid } = await form.value.validate();

  if (!valid) {
    return;
  }

  isLoading.value = true;

  await addAddress({
    ...form.value.getAddress(),
    supplier: props.supplierId,
  });
  await props.refreshData();

  isLoading.value = false;

  hideModal();
};
</script>

<template>
  <div class="results-amount">
    <span class="results-amount__text">
      {{ $t('resultsAmount') }}
    </span>
    <BaseSelect
      id="results-amount"
      v-model="results"
      name="results-amount"
      class="results-amount__select"
      :item-list="items"
      is-dense
    />
  </div>
</template>

<script lang="ts" setup>
import { BaseSelect } from '@ui-form';

interface Props {
  amount: number,
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'amount-change', amount: number): void
}>();

const results = computed<string>({
  get(){
    return String(props.amount);
  },
  set(value){
    $emit('amount-change', Number(value));
  },
});
const selectElements = Array.from({ length: 10 }, (_, index) => {
  const id = (index + 1) * 10;
  return {
    text: String(id),
    id: String(id),
  };
});
const items = ref(selectElements);
</script>

<style lang="scss" scoped>

.results-amount {
  margin-right: auto;
  display: flex;
  align-items: center;

  &__text {
    color: $c-tim-gray-dark;
    margin-right: 8px;

    @include to-sm {
      display: none;
    }
  }

  &__select {
    width: 75px;
    margin-top: 0;
    padding: 0;
  }
}
</style>

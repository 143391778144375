<template>
  <div
    ref="container"
    class="dropdown-options"
    :class="{ 'is-disabled': disabled }"
    v-on="triggerHandler"
  >
    <slot
      name="trigger"
      :is-open="isOpen"
    />
    <Teleport to="body">
      <div
        v-show="isOpen"
        ref="content"
        class="dropdown-options__menu"
        :style="{ width, minWidth: containerMinWidth && containerWidth, ...(sameAsContainerWidth && { width: containerWidth }) }"
        v-on="triggerHandler"
      >
        <slot :is-open="isOpen" />
      </div>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { createPopper, Modifier, Options } from '@popperjs/core';
import { GlobalDropdownPlacement } from '../model';
import { onKeyStroke, onClickOutside } from '@vueuse/core';

const sameWidthModifier: Modifier<string, Options> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${
      (state.elements.reference as Element).clientWidth
    }px`;
  },
};

type Props = {
  placement?: GlobalDropdownPlacement
  width?: string
  containerMinWidth?: boolean
  sameAsContainerWidth?: boolean
  sameWidth?: boolean,
  offset?: number[]
  isClickOnly?: boolean,
  preventClick?: boolean,
  closeOnContentClick?: boolean,
  modelValue?: boolean,
  disabled?: boolean,
}
const props = withDefaults(defineProps<Props>(), {
  placement: 'bottom-end',
  width: 'auto',
  containerMinWidth: undefined,
  sameWidth: false,
  offset: null,
  isClickOnly: false,
  preventClick: false,
  closeOnContentClick: true,
  modelValue: undefined,
  disabled: false,
});
const $emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
}>();

const content = ref(null);
const container = ref(null);
const localIsOpen = ref(false);
const isOpen = computed({
  get() {
    return props.modelValue ?? localIsOpen.value;
  },
  set(value) {
    localIsOpen.value = value;
    if (props.modelValue === undefined) {
      return;
    }
    $emit('update:modelValue', value);
  },
});

const clearOutsideClick = ref<ReturnType<typeof onClickOutside> | null>(null);

const containerWidth = ref('');
const popper = ref(null);
watch(isOpen, () => {
  if (!isOpen.value) {
    clearOutsideClick.value?.();
    return;
  }

  // set up click outside handler - only when dropdown is open
  clearOutsideClick.value = onClickOutside(container, (event) => {
    let currentElement = event.target as HTMLElement;
    while (currentElement && currentElement !== document.body) {
      if (currentElement.hasAttribute('data-prevent-outside-close')) {
        return;
      }
      currentElement = currentElement.parentElement;
    }

    // content and container are in diferent places in the DOM
    // thats why we need to check if the click was outside the content
    if (!content.value?.contains(event.target)) {
      isOpen.value = false;
    }
  });

  containerWidth.value = `${container.value.clientWidth}px`;

  if (!popper.value) {
    popper.value = createPopper(container.value, content.value, {
      placement: props.placement,
      modifiers: [
        props.offset && {
          name: 'offset',
          options: {
            offset: props.offset,
          },
        },
        props.sameWidth && sameWidthModifier,
      ].filter(Boolean),
    });
  } else {
    popper.value.update();
  }
});

const checkIfDisabled = (callback: () => void) => {
  if (props.disabled) {
    isOpen.value = false;
    return;
  }
  callback();
};

const canClose = (event: PointerEvent) => {
  if (props.closeOnContentClick) {
    return true;
  }

  // disallow close if the click is inside the content
  if (content.value?.contains(event.target)) {
    return false;
  }

  // clicked outside the content
  return true;
};

const triggerHandler = computed(() => {
  if (props.preventClick) {
    return {};
  }

  if (props.isClickOnly) {
    return {
      click: (event) => checkIfDisabled(() => {
        if (!isOpen.value) {
          isOpen.value = true;
        } else if (canClose(event)) {
          isOpen.value = false;
        }
      }),
    };
  }

  return {
    mouseover: () => checkIfDisabled(() => (isOpen.value = true)),
    mouseout: () => checkIfDisabled(() => (isOpen.value = false)),
    touchstart: () => checkIfDisabled(() => (isOpen.value = !isOpen.value)),
  };
});

onUnmounted(() => {
  if(popper.value){
    popper.value.destroy();
    popper.value = null;
  }
});

onKeyStroke('Escape', () => isOpen.value = false);
</script>

<style lang="scss" scoped>
.dropdown-options {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &.is-disabled {
    cursor: default;
  }

  &__menu {
    z-index: 250;
  }
}
</style>

<template>
  <span class="vertical-line" />
</template>
<script lang="ts" setup>
const props = defineProps<{ height: string }>();
</script>
<style lang="scss" scoped>
.vertical-line {
  height: v-bind('props.height');
  width: 1px;
  background: $c-tim-gray-light;
}
</style>

import { FormatedDateWithTime } from '@ui-base';
import { FileItem } from './File';

export enum MESSAGE_STATUS {
  ACTIVE = 'ACTIVE',
  ASSIGNED_ADMIN = 'ASSIGNED_ADMIN',
  ASSIGNED_SUPPLIER = 'ASSIGNED_SUPPLIER',
  ADDITIONAL_ADMIN = 'ADDITIONAL_ADMIN',
}

export type MessageItem = {
  id: Distinct<string, 'MessageId'>;
  user: {
    id: Distinct<string, 'MessageUserId'>;
    fullName: Distinct<string, 'MessageUserFullName'>;
    role?: Distinct<string, 'MessageUserRole'>;
    supplierId?: Distinct<string, 'MessageUserSupplierId'>;
  },
  createdAt: FormatedDateWithTime,
  content: Distinct<string, 'MessageContent'>,
  fileList: FileItem[],
  hasError?: Distinct<boolean, 'TemporaryErrorInfoAfterSendFail'>,
  isSending?: Distinct<boolean, 'TemporaryLoadingInfoAfterSend'>,
}

export type MessageItemWithStatus = MessageItem & {
  status: MESSAGE_STATUS
}

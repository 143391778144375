<template>
  <nav
    class="element-list-wrapper"
    :class="{ 'element-list-wrapper--mobile': mobile }"
  >
    <div
      class="element-list-wrapper__list"
      data-t="element-list-wrappe-list"
      v-on="{'click': handleFillUpDataToUseAppNotification}"
    >
      <slot />
    </div>
  </nav>
</template>

<script setup lang="ts">
import { BASE_NOTIFICATION_TYPES } from '@ui-base';
import { useUserRoles } from '@user-common';
import useToast from '@composables/useToast';
import { useSupplierCommon } from '@supplier-common';

withDefaults(defineProps<{ mobile?: boolean }>(), {
  mobile: false,
});

const { openToast } = useToast();

const userRoles = useUserRoles();
const supplierCommon = useSupplierCommon();

const handleFillUpDataToUseAppNotification = () => {
  if (!userRoles.isAdmin && !supplierCommon.isSupplierActive) {
    openToast(BASE_NOTIFICATION_TYPES.ERROR, 'Uzupełnij wymagane dane, aby uzyskać dostęp do wszystkich zakładek Panelu Dostawcy');
  }
};
</script>

<style lang="scss" scoped>
.element-list-wrapper {
  position: relative;
  display: flex;
  margin-left: auto;
  height: 100%;

  &__list {
    display: flex;
    align-items: center;
    padding-left: 18px;
  }

  &--mobile {
    padding: 27px 32px;

    .element-list-wrapper__list {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }
  }
}
</style>

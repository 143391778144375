<template>
  <GlobalDropdown
    v-model="isOpen"
    data-t="BaseTooltip"
    :placement="placement"
    class="tooltip-container"
    :class="{ 'tooltip-container--light': light }"
    :offset="[0, 10]"
  >
    <template #trigger>
      <slot />
    </template>
    <div
      class="tooltip-container__tooltip"
      :class="center && 'tooltip-container__tooltip--center'"
      :style="{ ...(Number(maxWidth) && { maxWidth: `${maxWidth}px` }) }"
    >
      <slot name="tooltip" />
    </div>
  </GlobalDropdown>
</template>

<script lang="ts" setup>
import { GlobalDropdownPlacement } from '@ui-base';

interface Props {
  light?: boolean,
  placement?: GlobalDropdownPlacement
  forceOpen?: boolean
  maxWidth?: number | string
  center?: boolean
  fullWidth?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  light: false,
  placement: 'bottom-end',
  maxWidth: 0,
  fullWidth: false,
});

const isOpen = ref(false);

watch(
  () => props.forceOpen,
  () => {
    if (typeof props.forceOpen !== 'boolean') {
      return;
    }

    isOpen.value = props.forceOpen;
  },
  { immediate: true },
);

const width = computed(() => props.fullWidth ? '100%' : 'fit-content');
</script>

<style lang="scss" scoped>

.tooltip-container {
  cursor: help;
  position: relative;
  width: v-bind(width);
  display: inherit;

  &__tooltip {
    @include t9;

    width: max-content;
    background-color: $c-tim-gray;
    border-radius: 4px;
    box-shadow: 0 3px 3px rgba($c-black, 0.04);
    padding: 4px 8px;
    color: $c-white;
    z-index: 250;

    &--center {
      text-align: center;
    }
  }

  &--light {
    .tooltip-container__tooltip {
      background-color: $c-white;
      color: $c-black;
    }
  }
}
</style>

import { toDateFormatWithTime } from '@ui-base';
import { THREAD_STATUS, SupplierThreadItem, SegmentItem, SupplierThreadDetails } from '../model';
import { isUserActionRequired, mapMessageItem } from './common';

export const mapSupplierThreadDetails = (unMappedThread: {
  id: string,
  status: string,
  segment: { name: string },
  number: string,
  isNewMessageForSupplier: boolean,
  admin: {
    id: string | null,
  },
  supplier: {
    id: string,
  },
  messages: {
    id: string,
    user: {
      id: string,
      name: string,
      position?: string,
    },
    createdAt: string,
    content: string,
    files: { id: string, originalName: string, mimeType: string }[],
  }[]
}): SupplierThreadDetails => {
  return {
    id: unMappedThread.id as SupplierThreadDetails['id'],
    admin: {
      id: (unMappedThread.admin?.id || null) as SupplierThreadDetails['admin']['id'],
    },
    supplier: {
      id: unMappedThread.supplier.id as SupplierThreadDetails['supplier']['id'],
    },
    status: unMappedThread.status as THREAD_STATUS,
    segment: {
      name: unMappedThread.segment.name as SegmentItem['name'],
    },
    number: unMappedThread.number as SupplierThreadDetails['number'],
    requireUserAction: isUserActionRequired({ status: unMappedThread.status, isNewMessage: unMappedThread.isNewMessageForSupplier }),
    messageList: unMappedThread.messages.map(mapMessageItem),
  };
};

export const mapSupplierThreadItem = (unMappedThread: {
  id: string,
  title: string,
  status: string,
  segment: { name: string },
  number: string,
  createdAt: string,
  isNewMessageForSupplier: boolean,
}): SupplierThreadItem => {
  return {
    id: unMappedThread.id as SupplierThreadItem['id'],
    title: unMappedThread.title as SupplierThreadItem['title'],
    status: unMappedThread.status as THREAD_STATUS,
    segment: {
      name: unMappedThread.segment.name as SegmentItem['name'],
    },
    number: unMappedThread.number as SupplierThreadItem['number'],
    createdAt: toDateFormatWithTime(unMappedThread.createdAt),
    requireUserAction: isUserActionRequired({ status: unMappedThread.status, isNewMessage: unMappedThread.isNewMessageForSupplier }),
  };
};

<template>
  <slot v-if="isFlagEnabled" />
</template>

<script setup lang="ts">
import { useFeatureFlag } from '../composables/useFeatureFlag';
import { FEATURE_FLAGS } from '../model';

type Props = {
  flagName: FEATURE_FLAGS,
}
const props = defineProps<Props>();

const { isFlagEnabled } = useFeatureFlag(props.flagName);
</script>

import baseService, { toFormData } from '@services/baseService';
import { useRequest } from '@request';
import { SupplierThreadDetails, SupplierThreadItem, THREAD_STATUS, ThreadId } from '../model';
import { mapSupplierThreadDetails, mapSupplierThreadItem } from '../mappers';
import { CreateSupplierMessageInThread, CreateSupplierThread } from '../model/Payload';
import _omit from 'lodash/omit';

export const getThreadList = async (payload: { page: number, status: THREAD_STATUS | null,  }): Promise<{ list: SupplierThreadItem[], total: number }> => {
  const { callRequest } = useRequest();

  const normalizedOptions = new URLSearchParams({
    page: String(payload.page),
    ...(payload.status ? { status: payload.status } : {}),
  });
  const url = `/threads?${normalizedOptions}`;

  const { data } =  await callRequest(
    baseService.get<{
      'hydra:member': {
        id: string,
        title: string,
        status: string,
        segment: { name: string },
        number: string,
        createdAt: string,
        isNewMessageForSupplier: boolean,
      }[],
      'hydra:totalItems': number
    }>(url, { headers: { 'Accept': 'application/ld+json' } }),
    {
      transformData: (list) => ({
        total: list['hydra:totalItems'],
        list: list['hydra:member'].map(mapSupplierThreadItem),
      }),
      errorFallbackData: () => ({ list: [], total: 0 }),
    },
  );

  return data;
};

export const getThreadDetails = async (threadId: ThreadId): Promise<{ data: SupplierThreadDetails, isCancel: boolean }> => {
  const { callRequest, abortToken } = useRequest();

  const url = `/threads/${threadId}`;
  const { data, isCancel } =  await callRequest(
    baseService.get<{
      id: string,
      status: string,
      segment: { name: string },
      number: string,
      isNewMessageForSupplier: boolean,
      admin: {
        id: string,
      },
      supplier: {
        id: string,
      },
      messages: {
        id: string,
        user: {
          id: string,
          name: string,
          position?: string,
        },
        createdAt: string,
        content: string,
        files: { id: string, originalName: string, mimeType: string }[],
      }[]
    }>(url, { cancelToken: abortToken(url) }),
    {
      transformData: (details) => mapSupplierThreadDetails(details),
      errorFallbackData: () => null,
    },
  );

  return { data, isCancel };
};

export const createNewThread = async (payload: CreateSupplierThread): Promise<{ hasError: boolean }> => {
  const { callRequest } = useRequest();

  const formData = toFormData(_omit(payload, ['files']));
  payload.files.forEach((file) => {
    formData.append('files[]', file);
  });

  const { hasError } = await callRequest(
    baseService.post(`/threads`, formData),
    {
      successMessage: 'Wątek został utworzony',
    },
  );

  return { hasError };
};

export const createNewMessageInThread = async (payload: CreateSupplierMessageInThread): Promise<{ hasError: boolean }> => {
  const { callRequest } = useRequest();

  const formData = toFormData(_omit(payload, ['files']));
  payload.files.forEach((file) => {
    formData.append('files[]', file);
  });

  const { hasError } = await callRequest(baseService.post(`/messages`, formData));

  return { hasError };
};

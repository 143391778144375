export const enum ROUTER_LINKS {
  HOME = '/',
  PRODUCTS = '/produkty',
  PRODUCTS_MANAGEMENT = '/produkty/zarzadzanie-produktami',
  PRODUCTS_IMPORT = '/produkty/import-produktow',
  PRODUCTS_IMPORT_CONFIGURATION_ADD = '/produkty/import-produktow/dodawanie-schematu',
  PRODUCTS_IMPORT_CENEO = '/produkty/import-produktow/dodawanie-schematu/ceneo',
  PRODUCTS_IMPORT_ADD = '/produkty/import-produktow/dodawanie-importu',
  PRODUCTS_IMPORT_ADD_SUMMARY = '/produkty/import-produktow/dodawanie-importu/podsumowanie',
  PRODUCTS_CENEO_VALIDATOR = `/produkty/import-produktow/dodawanie-schematu/ceneo/nowy/konfiguracja`,
  SALES = '/sprzedaz',
  SALES_EDI_CONFIGURATION = '/sprzedaz/edi',
  SALES_ORDERS = '/sprzedaz/zamowienia',
  SALES_ORDERS_SHIPPING = '/sprzedaz/zlecenie-transportowe',
  SALES_SHIPMENTS = '/sprzedaz/wysylki',
  SALES_INVOICES = '/sprzedaz/faktury',
  MARKETING = '/marketing',
  MARKETING_MY_MARKETING = '/marketing/moj-marketing',
  MARKETING_BANNERS = '/marketing/banery',
  MARKETING_CAMPAIGNS = '/marketing/kampanie',
  AFTER_SALES = '/obsluga-posprzedazowa',
  AFTER_SALES_SCORING = '/obsluga-posprzedazowa/ocena-i-jakosc-obslugi',
  LOGIN = '/logowanie',
  REGISTER = '/rejestracja',
  REGISTER_THANK_YOU = '/podziekowanie-za-rejestracje',
  REGISTER_INACTIVE = '/rejestracja-nieaktywna',
  REGISTER_ALREADY_EXIST = '/rejestracja-istnieje',
  LOGIN_INACTIVE = '/logowanie-nieaktywne',
  LOGIN_LINK_SEND = '/link-wyslany',
  RESET_PASSWORD = '/resetuj-haslo',
  SET_PASSWORD = '/ustaw-haslo',
  SET_PASSWORD_INACTIVE = '/ustaw-haslo-nieaktywne',
  ERROR_503 = '/serwis-niedostepny',
  DOCS = '/docs',
  SETTINGS = '/ustawienia',
  SETTINGS_USER = '/ustawienia/moj-profil',
  SETTINGS_SUPPLIER_ACCOUNT = '/ustawienia/moje-konto',
}

export enum FILE_LINKS {
  RULES_PROJECT = '/files/regulamin/Panel dostawcy_Regulamin_16.04.2024.pdf',
  RULES_PRIVACY = '/files/regulamin/Panel_Dostawcy_polityka prywatności_16.04.2024.pdf',
  RULES_OWZ = '/files/regulamin/OWZ_TIM_16.04.2024.pdf',
  CENEO_IMPORT = '/files/Instrukcja_importu_XML_-_Ceneo.pdf',
}

export enum FILE_NAMES {
  CENEO_IMPORT = 'Instrukcja_importu_XML_-_Ceneo',
}

export const enum PRODUCT_IMPORT_VIEW_TYPE {
  SCHEMAS = 'schematy',
  EXECUTION = 'wywolania'
}

export const enum COMPANY_SIZE {
  BIG = 'BIG',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  MICRO = 'MICRO'
}

export enum BASE_ACTIONS {
  EDIT = 'edit',
  DELETE = 'delete',
}

export const enum IMPORT_CONFIGURATION_STATUS {
  NEW = 'NEW',
  SCHEDULED = 'SCHEDULED',
  PROCESSING = 'PROCESSING',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  MAPPING_CATEGORIES = 'MAPPING_CATEGORIES',
  MAPPING_MANUFACTURERS = 'MAPPING_MANUFACTURERS',
  MAPPING_UNITS = 'MAPPING_UNITS',
  MAPPING_FINISHED = 'MAPPING_FINISHED',
  CONFIRMED = 'CONFIRMED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  READY = 'READY',
}

export const enum PRODUCT_IMPORTS_STATUS {
  NEW = 'NEW',
  WAITING_MAPS = 'WAITING_MAPS',
  SCHEDULED = 'SCHEDULED',
  PROCESSING = 'PROCESSING',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  IMPORTING_SCHEDULED = 'IMPORTING_SCHEDULED',
  IMPORTING = 'IMPORTING',
  IMPORTING_FAILED = 'IMPORTING_FAILED',
  FINISHED = 'FINISHED'
}

export const enum IMPORT_CONFIGURATION_TRANSITIONS {
  SCHEDULE = 'schedule',
  CONFIRM_CATEGORIES = 'confirm_categories',
  CONFIRM_MANUFACTURERS = 'confirm_manufacturers',
  CONFIRM_UNITS = 'confirm_units',
  CONFIRM = 'confirm',
  ACCEPT = 'accept',
  DECLINE = 'decline',
}

export const enum PRODUCT_IMPORTS_TRANSITIONS {
  SCHEDULE = 'schedule',
}

export const enum IMPORT_FILE_TYPE {
  CENEO = 'CENEO',
  BMECAT = 'BMECAT',
}

export const enum IMPORT_PROCESSING_STAGE {
  DOWNLOAD = 'download',
  EXTRACT = 'extract',
  PARSE = 'parse',
  VALIDATE = 'validate',
  BUILD_MAP = 'build_map',
}

export const enum PROGRESS_COLORS {
  TIM_RED = '#d70000',
  TIM_GREEN = '#4DAF50',
  TIM_BLUE = '#2196f3',
}

export enum CONFIRMATION_FILTER {
  UNCONFIRMED = 'UNCONFIRMED',
  PARTIAL = 'PARTIAL',
  CONFIRMED = 'CONFIRMED',
}

export enum CONFIRMED_STATUS {
  UNCONFIRMED_DELAYED_ORDERS_COUNT_ORDERS_COUNT = 'unconfirmedDelayedOrdersCount',
  CONFIRMED_NOT_DELAYED_ORDERS_COUNT = 'confirmedNotDelayedOrdersCount',
  UNCONFIRMED_NOT_DELAYED_ORDERS_COUNT = 'unconfirmedNotDelayedOrdersCount',
  PARTIAL_CONFIRMED_ORDERS_COUNT = 'partialConfirmedOrdersCount',
  CONFIRMED_DELAYED_ORDERS_COUNT = 'confirmedDelayedOrdersCount',
  WITHOUT_SHIPMENT_ORDERS_COUNT = 'withoutShipmentOrdersCount'
}

export enum CARRIERS {
  GLS = 'GLS',
  DPD = 'DPD',
  DHL = 'DHL',
  INPOST = 'INPOST',
  RABEN = 'RABEN',
  CUSTOM = 'CUSTOM',
}

export enum TIM_SHIPMENT_CARRIERS {
  DPD = 'DPD',
  INPOST = 'INPOST',
  RABEN = 'RABEN',
}

export enum PACKAGE_TYPES {
  PARCEL = 'PARCEL',
  PALLET = 'PALLET',
  PARCEL_LOCKER = 'PARCEL_LOCKER'
}

// @TODO: shipping actions mocks
export enum SHIPPING_ACTIONS {
  GENERATE_LABELS = 'GENERATE_LABELS'
}

// @TODO: shipping status mocks
export enum SHIPPING_STATUS {
  MISSED = 'MISSED',
  READY_TO_SEND = 'READY_TO_SEND',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
}

export enum INVOICES_STATUS {
  SENDING_SCHEDULED = 'SENDING_SCHEDULED',
  SENDING = 'SENDING',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  ERROR = 'ERROR',
  DRAFT = 'DRAFT',
}

export enum STATUS_TYPES {
  REGISTRATION =  'registration',
  USER = 'user',
  SUPPLIER = 'supplier',
  ORDER = 'order',
  SHIPPING = 'shipping',
  INVOICES = 'invoices',
}

export enum WIZARD_STEPS {
  CONFIG = 1,
  VERIFICATION = 2,
  CATEGORIES_MAP = 3,
  MANUFACTURERS_MAP = 4,
  UNITS_MAP = 5,
  SUMMARY = 6
}

export enum WIZARD_URL_NAMES {
  CENEO = 'AppProductsImportCeneo',
  BMECAT = 'AppProductsImportBmecat'
}

export enum WIZARD_ID_STEPS {
  NEW = 'nowy',
}

export enum FILE_TYPES {
  FILE = 'FILE',
  URL = 'URL'
}

export enum MEASURE_UNIT {
  KG = 'KG',
  KM = 'KM',
  PIECE = 'PIECE',
  SET = 'SET',
  PACKAGE = 'PACKAGE',
  M = 'M',
  PAIR = 'PAIR',
}

export enum TAX_RATE {
  ZERO = '0',
  FIVE = '5',
  EIGHT = '8',
  DEFAULT = '23',
  NOT_SEPCIFIED = 'zw',
}

export enum MULTI_CULTI_TYPE {
  ORDER_ITEM = 'ORDER_ITEM',
}

export enum DASHBOARD_PERIOD {
  MONTHS = 'months'
}
  
export enum DASHBOARD_UNIT {
  PRICE = 'zł',
  PIECE = 'szt.'
}

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc'
}

export enum APP_EMAILS {
  MARKET_TIM = 'markettim@tim.pl'
}

export enum COUNTRY {
  POLAND = 'PL'
}

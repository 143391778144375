<template>
  <BaseTextInput
    v-bind="props"
    ref="field"
    v-model="input"
    @blur="$emit('blur')"
  >
    <template #above-input>
      <slot name="above-input" />
    </template>
    <template #before-input>
      <slot name="before-input" />
    </template>
    <template #after-input>
      <slot name="after-input" />
    </template>
  </BaseTextInput>
</template>
<script setup lang="ts">
import BaseTextInput from './BaseTextInput.vue';
import { BaseTextInputProps } from '../model';
import flow from 'lodash/flow';

type Props = Omit<BaseTextInputProps, 'modelValue'> & {
  modelValue?: string | number
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'update:modelValue', value: number): void
  (event: 'blur'): void
}>();

const toString = (value: string | number) => String(value);
const removeLetters = (value: string) => value.replace(/[^0-9(.|,)]+/g, '');
const keepOnlyOneZero = (value: string) => value.replace(/^00/, '0');
const keepLastTwoNumbers = (splitBy: string) => (value: string) => {
  if (value.includes(splitBy)) {
    const [before, after] = value.split(splitBy);
    return `${before}${splitBy}${after.slice(0, 2).trim()}`;
  }

  return value;
};
const clearLeadingZero = (value: string) => {
  if (/^0\d/.test(value)) {
    return value.replace(/^0/, '');
  }
  return value;
};
const getNumericValue = (value: string | number) => {
  const changedValue: string = flow([
    toString,
    removeLetters,
    keepOnlyOneZero,
    clearLeadingZero,
    keepLastTwoNumbers('.'),
    keepLastTwoNumbers(','),
  ])(value);

  const rawValue = parseFloat(changedValue.replace(',', '.'));
  const numericValue = Number.isFinite(rawValue) ? rawValue : undefined;

  return {
    value: numericValue,
    input: changedValue ?? '',
  };
};

const field = ref(null);
const inputElement = shallowRef(null);
const input = computed({
  get(): string {
    if (isNaN(Number(props.modelValue))) {
      return '';
    }

    return String(props.modelValue ?? '');
  },
  set(value: string | number) {
    const changed = getNumericValue(value);
    $emit('update:modelValue', changed.value);
    nextTick(() => {
      if (!inputElement.value) {
        inputElement.value = field.value?.$el?.querySelector('input');
      };

      if (!inputElement.value) {
        return;
      }

      inputElement.value.value = changed.input;
    });
  },
});
</script>

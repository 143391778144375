<template>
  <GlobalModal
    :model-value="true"
    :header="$t('warehouseAddressSupplier.editFormHeader')"
    :is-loading="isLoading"
    @save="handleSave"
    @close="hideModal"
  >
    <AddressModalForm
      ref="form"
      :is-loading="isLoading"
      :warehouse-address="warehouseAddress"
    />
  </GlobalModal>
</template>
<script lang="ts" setup>
import AddressModalForm from './AddressModalForm.vue';
import { useModal } from '@ui-base';
import { EditWarehouseAddress } from '../model';
import { getAddress, editAddress } from '../service';

const { hideModal } = useModal();
  
const props = defineProps<{
  addressId: string,
  refreshData: () => Promise<void>
}>();
  
// Form data
const warehouseAddress = ref<EditWarehouseAddress>();

const isLoading = ref(false);
const form = ref();
const handleSave = async () => {
  const { valid } = await form.value.validate();

  if (!valid) {
    return;
  }

  isLoading.value = true;

  await editAddress(props.addressId, form.value.getAddress());
  await props.refreshData();

  isLoading.value = false;

  hideModal();
};

async function setWarehouseAddress() {
  isLoading.value = true;

  warehouseAddress.value = await getAddress(props.addressId);

  isLoading.value = false;
}
setWarehouseAddress();
</script>

<template>
  <div
    v-show="actions.length"
    class="actions"
  >
    <div class="actions__wrapp">
      <BaseTooltip
        v-for="(action) in actions"
        :key="action.name"
        class="actions__icon"
        :class="[{ 'is-disabled': action.isDisabled, 'is-wide': action.isWide }]"
        :data-t="`action-${action.name}`"
        @click="handleTrigger(action)"
      >
        <slot :name="`icon-${action.name}`">
          <GlobalIcon
            v-if="action.icon"
            :name="action.icon"
          />
        </slot>
        <template #tooltip>
          {{ action.text }}
        </template>
      </BaseTooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseTooltip from '@ui/BaseTooltip.vue';
import { TableActionItem } from '../model';

interface Props {
  actions: TableActionItem[]
}
defineProps<Props>();

const handleTrigger = (action: TableActionItem) => {
  if (action.isDisabled) {
    return;
  }

  action.onTrigger(action);
};
</script>

<style lang="scss" scoped>
.actions {
  padding-left: 16px;

  &__icon {
    margin-right: 18px;
    color: $c-tim-gray-dark;
    transition: color $base-transition;
    cursor: pointer;
    user-select: none;

    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.is-wide {
      padding-left: 18px;
      border-left: 1px solid $c-tim-gray-light;

      &:first-of-type {
        padding-left: 0;
      }
    }

    &:first-of-type {
      border-left: none;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $text-color-primary;
    }
  }

  &__wrapp {
    display: flex;
    align-items: center;

    .actions__icon {
      margin-right: 18px;
    }
  }
}
</style>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-if="isOpen"
        ref="modal"
        class="base-modal"
        :class="{ 'base-modal--fullscreen': fullscreen || isMobile}"
        tabindex="0"
      >
        <div
          :class="{ 'base-modal__container--no-overflow': disableOverflow }"
          class="base-modal__container"
        >
          <div class="base-modal__header">
            <GlobalIcon
              name="close"
              class="base-modal__close"
              tabindex="0"
              @click="handleClose"
              @keydown.enter="handleClose"
            />
          </div>
          <div class="base-modal__box">
            <h1
              v-if="header"
              class="base-modal__title"
            >
              {{ header }}
            </h1>
            <div class="base-modal__content">
              <div class="base-modal__content-inner">
                <slot />
              </div>
            </div>
            <div
              v-if="!hideActions"
              class="base-modal__actions"
            >
              <slot
                name="actions"
                :handle-close="handleClose"
              >
                <GlobalButton
                  data-t="base-modal-save"
                  :loading="isLoading"
                  :disabled="saveButtonDisabled"
                  @click="$emit('save')"
                >
                  {{ saveButtonText }}
                </GlobalButton>

                <GlobalButton
                  data-t="base-modal-close"
                  class="actions__space"
                  outlined
                  no-border
                  @click="handleClose"
                >
                  {{ closeButtonText }}
                </GlobalButton>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import useMobile from '../../composables/useMobile';
import { onKeyStroke } from '@vueuse/core';

interface Props {
  modelValue: boolean
  isLoading?: boolean,
  saveButtonDisabled?: boolean,
  saveButtonText?: string,
  closeButtonText?: string,
  header?: string
  disableOverflow?: boolean,
  fullscreen?: boolean,
  hideActions?: boolean
  verticalPadding?: number
}
const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
  saveButtonDisabled: false,
  saveButtonText: 'zapisz',
  closeButtonText: 'anuluj',
  header: '',
  disableOverflow: false,
  fullscreen: false,
  hideActions: false,
  verticalPadding: 0,
});
const $emit = defineEmits(['save', 'close', 'update:modelValue']);
const { isMobile } = useMobile();

const isOpen = computed({
  get(){
    return props.modelValue;
  },
  set(value) {
    $emit('update:modelValue', value);
  },
});

const handleClose = () => {
  isOpen.value = false;
  $emit('close');
};

const modal: Ref<HTMLDivElement> = ref(null);
watch(isOpen, (newValue) => {
  if(newValue) {
    nextTick(() => {
      modal.value.focus();
    });
  }
});

onKeyStroke('Escape', handleClose);

const normalizedVerticalPadding = computed(() => {
  return `${props.verticalPadding}px`;
});
</script>

<style lang="scss" scoped>

$header-height: 44px;

.base-modal {
  width: 100%;
  height: 100%;
  background-color: rgba($c-tim-gray, 0.82);
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  &__box {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    padding: 12px 80px 56px;
  }

  &__content {
    scrollbar-gutter: auto;
    overflow: hidden auto;

    &-inner {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      padding: v-bind('normalizedVerticalPadding') 0;
    }
  }

  &__container {
    margin: 20px;
    background: $c-white;
    box-shadow: $base-shadow;
    border-radius: 4px;
    max-width: 660px;
    width: 100%;
    overflow: hidden;

    &--no-overflow {
      overflow: initial;

      .base-modal__content {
        overflow: initial;
      }
    }
  }

  &__header {
    width: 100%;
    display: flex;
    padding: 20px 20px 0;
  }

  &__title {
    @include t3;

    width: 100%;
    max-width: 500px;
    margin: 0 0 16px;
    text-align: center;
  }

  &__close {
    cursor: pointer;
    margin-left: auto;
  }

  &__actions {
    width: 100%;
    max-width: 500px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;

    .actions__space {
      margin-top: 16px;
    }
  }

  &--fullscreen {
    .base-modal__container {
      width: 100%;
      max-width: 100vw;
      margin: 0;

      .base-modal__box {
        height: calc(100vh - $header-height);
        max-height: calc(100vh - $header-height);

        @include to-sm {
          padding: 16px;
        }
      }

      .base-modal__content-inner {
        margin: 0;
        max-width: inherit;
      }

      .base-modal__actions {
        flex-direction: row-reverse;
        justify-content: flex-end;
        max-width: 100vw;

        button {
          margin: 0 8px 0 0;
        }

        @include to-sm {
          justify-content: center;

          button {
            width: 100%;
          }
        }
      }
    }
  }
}

:global(.fade-enter-active),
:global(.fade-leave-active) {
  transition: opacity 0.2s ease;
}

:global(.fade-enter-from),
:global(.fade-leave-to) {
  opacity: 0;
}

:global(.modal-subheader) {
  @include t5;

  margin: 0 0 24px;
}
</style>

<template>
  <ul
    class="base-list"
    :class="{ 'base-list--overflow': overflow }"
  >
    <slot />
  </ul>
</template>

<script lang="ts" setup>
interface Props {
  maxWidth?: string
  overflow?: boolean
  maxHeight?: string
}

const props = withDefaults(defineProps<Props>(), {
  maxWidth: '100%',
  overflow: false,
  maxHeight: '120px',
});
</script>

<style lang="scss" scoped>

.base-list {
  width: 100%;
  max-width: v-bind("props.maxWidth");
  padding: 8px 0;
  background-color: $c-white;
  box-shadow: 0 4px 5px rgba($c-black, 0.14), 0 1px 10px rgba($c-black, 0.12), 0 2px 4px rgba($c-black, 0.2);

  &--overflow {
    scrollbar-gutter: auto;
    overflow: hidden auto;
    max-height: v-bind("props.maxHeight");
  }
}
</style>

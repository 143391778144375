import { MessageItem } from '../../model';

type ComponentWithEl = {
  $el: HTMLElement;
}
export const useScrollMessageBox = () => {
  // Scroll to bottom when new message occurs
  const setScrollToBottom = <T extends ComponentWithEl>(
    contentRef: Ref<T | null>,
    messageList: Ref<MessageItem[]>,
  ) => {
    const scrollToBottom = async () => {
      await nextTick();
      if (!contentRef.value) {
        return;
      }
  
      contentRef.value.$el.scrollTo({
        top: contentRef.value.$el.scrollHeight,
        behavior: 'smooth',
      });
    };

    watch(
      () => messageList.value.length,
      scrollToBottom,
    );

    onMounted(scrollToBottom);
  };

  return { setScrollToBottom };
};

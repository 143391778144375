import { ROUTER_LINKS } from '@enums/global.enums';
import { RouteRecordRaw } from 'vue-router';

export const createSettingsUserRoute = (): RouteRecordRaw => ({
  path: ROUTER_LINKS.SETTINGS,
  name: 'AppSettingsUser',
  redirect: ROUTER_LINKS.SETTINGS_USER,
  component: () => import('@pages/EmptyRouterView.vue'),
  meta: {
    pageTitle: 'Ustawienia',
    layout: 'div',
  },
  children: [
    {
      path: ROUTER_LINKS.SETTINGS_USER,
      name: 'AppSettingsUserProfile',
      component: () => import('./components/PageView.vue'),
      meta: {
        pageTitle: 'Mój profil',
      },
    },
  ],
});

<template>
  <div class="checkbox-container">
    <input
      :id="id"
      v-model="inputValue"
      :name="name"
      type="checkbox"
      :value="value"
      class="checkbox-container__input"
      :disabled="disabled"
    >
    <label
      class="checkbox-container__checkbox"
      :class="checkboxClasses"
      :for="id"
    />
    <slot name="label">
      <label :for="id">{{ label }}</label>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import useValidation from '@composables/useValidation';

type CheckboxValue = boolean | string | number

interface Props {
  id: string,
  name: string,
  modelValue: boolean | CheckboxValue[],
  label?: string,
  rules?: string,
  value?: CheckboxValue,
  isLocalValid?: boolean,
  disabled?: boolean
}

const props =  withDefaults(defineProps<Props>(), {
  name: '',
  label: '',
  rules: '',
  value: true,
  isLocalValid: true,
});
const $emit = defineEmits(['update:modelValue', 'local-valid']);

const inputValue: WritableComputedRef<boolean | CheckboxValue[]> = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    $emit('update:modelValue', value);
    const isValueArray = Array.isArray(value);
    const isValueValid = isValueArray ? value.includes(props.value) : !!value;
    $emit('local-valid', isValueValid);
  },
});

const { isInvalidState } = useValidation(props.name);
const checkboxClasses = computed(() => {
  return {
    'is-checked': Array.isArray(inputValue.value) ? inputValue.value.includes(props.value) : inputValue.value,
    'is-invalid': isInvalidState.value || !props.isLocalValid,
    'is-disabled': props.disabled,
  };
});

</script>

<style lang="scss" scoped>

.checkbox-container {
  display: flex;
  margin: 16px 0;

  &__checkbox {
    margin: 3px 9px 0 0;
    flex: 0 0 18px;
    transition: all $base-transition;
    width: 18px;
    height: 18px;
    border: 2px solid $c-tim-gray;
    border-radius: 2px;
    cursor: pointer;

    &.is-checked {
      background: $c-tim-blue url("/src/assets/icons/icon-checkbox.svg") no-repeat center;
      background-size: 14px;
      border-color: $c-tim-blue;
    }

    &.is-invalid {
      border-color: $c-tim-red;
    }

    &.is-disabled {
      border-color: $c-tim-gray;

        &.is-checked {
          background: $c-tim-gray url("/src/assets/icons/icon-checkbox.svg") no-repeat center;
          background-size: 14px;
        }
    }
  }

  &__label {
    color: $c-tim-gray-dark;
  }

  &__input {
    display: none;
  }
}
</style>

<template>
  <div class="file-list-decorator">
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.file-list-decorator {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 10px;
  max-width: 100%;
}
</style>

<template>
  <div class="page-view-wrapper">
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.page-view-wrapper {
  display: grid;
  height: 100%;
  grid-template-rows: max-content;
}
</style>

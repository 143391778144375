import { AxiosError, AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import { BASE_NOTIFICATION_TYPES } from '@ui-base';
import { getErrorMessage } from './helpers';
import useToast from '@composables/useToast';
import { ErrorInfo } from './model';

interface CallRequestResult <T, E>{
  data: T,
  error: AxiosError<E> | null,
  hasError: boolean,
  isCancel: boolean,
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders | null
}

type CallRequest = <T, E>(result: CallRequestResult<T, E>) => CallRequestResult<T, E>

export const createCallRequest = (
  context: {
    openToast: ReturnType<typeof useToast>['openToast'],
  }) => {
  async function callRequest<T, U = T, E = unknown>(
    request: Promise<AxiosResponse<T>>,
    options: {
      successMessage?: string,
      errorMessage?: string,
      transformData?: (data: T) => U
      errorFallbackData?: (error: AxiosError) => U
    } = {},
  ): Promise<CallRequestResult<U, E>> {
    const successToast = createSuccessToast(options.successMessage);
    const errorToast = createErrorToast(options.errorMessage);
    
    try {
      const { data, headers } = await request;
    
      const transformedData = (options.transformData ? options.transformData(data) : data) as U;
    
      return successToast({
        data: transformedData,
        error: null,
        hasError: false,
        isCancel: false,
        headers,
      });
    } catch (rawError) {
      const error = (rawError as AxiosError<E>);
      const transformedError = (options.errorFallbackData ? options.errorFallbackData(error) : error) as U;
      
      const isCancel = error.message.includes('ABORT_TOKEN_ERROR');
      if (isCancel) {
        return {
          data: transformedError,
          error: (error as AxiosError<E>),
          hasError: true,
          isCancel: true,
          headers: null,
        };
      }

      if (process.env.NODE_ENV !== 'test') {
        console.error(error);
      }
      
      return errorToast({
        data: transformedError,
        error: (error as AxiosError<E>),
        hasError: true,
        isCancel: false,
        headers: null,
      });
    }
  }

  function createSuccessToast (successMessage?: string): CallRequest {
    return (result) => {
      if (result.hasError) {
        return result;
      }

      if(successMessage && typeof successMessage === 'string') {
        context.openToast?.('SUCCESS' as BASE_NOTIFICATION_TYPES, successMessage);
      }

      return result;
    };
  }

  function createErrorToast (errorMessage?: string): CallRequest {
    return (result) => {
      if (!result.hasError) {
        return result;
      }

      const { message: beMessage, skipNotification }  = getErrorMessage(result.error as AxiosError<ErrorInfo>);
  
      // Skip notification when BE wants to (then we will get `skipNotification: true` from BE)
      if (!skipNotification) {
        const message = typeof errorMessage === 'string' ? errorMessage : beMessage;
        context.openToast?.('ERROR' as BASE_NOTIFICATION_TYPES, message);
      }

      return result;
    };
  }

  return callRequest;
};

<template>
  <div class="thread-header-expand">
    <span class="text">
      Panel wiadomości
      <span
        v-if="messageCount"
        data-t="thread-header-expand-message-count"
        class="message-count"
      >{{ messageCount }}</span>
    </span>
    <div class="button-wrapper">
      <VerticalLine :height="MAX_HEIGHT" />
      <GlobalButton
        class="button-expand"
        data-t="thread-header-expand-button-expand"
        as-text
        @click.stop="handleExpand"
      >
        <GlobalIcon
          data-t="thread-header-expand-icon-expand"
          :name="expandIconName"
          is-symbol
        />
      </GlobalButton>
    </div>
  </div> 
</template>
<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { MessageCountItem } from '../../../model';
import VerticalLine from '../../common/VerticalLine.vue';

type Props = {
  isExpanded: boolean
  messageCount: MessageCountItem
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'update:isExpanded', isExpanded: boolean): void
}>();
const isExpanded = useVModel(props, 'isExpanded', $emit);

const handleExpand = () => isExpanded.value = !isExpanded.value;

const expandIconName = computed(() => isExpanded.value ? 'collapse_content' : 'expand_content');

const MAX_HEIGHT = `24px`;
</script>
<style lang="scss" scoped>
.thread-header-expand {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $c-white;
}

.message-count {
  padding: 0 4px;
  background: $c-tim-gray-lightest;
  border-radius: 2px;
  margin-left: 8px;
}

.button-expand {
  margin-left: 16px;
  width: 24px;
  height: v-bind(MAX_HEIGHT);
  color: $text-color-primary;

  @include custom-button;
}

.text {
  @include t5;
}

.button-wrapper {
  display: flex;
}
</style>

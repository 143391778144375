<template>
  <div
    :col-id="colId"
    class="cell-item"
    :class="{ 'with-bold': bold, 'is-link': isLink }"
    :title="isLink ? 'Widok szczegółowy' : undefined"
    @click="isLink ? $router.push(to) : undefined"
  >
    <div class="cell-item__content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ROUTER_LINKS } from '@enums/global.enums';

interface Props {
  colId: string | number
  overflow?: string
  withLink?: boolean
  bold?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  overflow: 'hidden',
  withLink: false,
  bold: false,
});

const $router = useRouter();

const to = inject<ROUTER_LINKS | RouteLocationRaw | undefined>('tableRowLink');

const isLink = computed(() => props.withLink && to);
</script>

<style lang="scss" scoped>

.cell-item {
  @include t9;

  font-weight: 400;
  padding-right: 32px;
  display: flex;
  align-items: center;

  &.is-link {
    cursor: pointer;
  }

  &.with-bold {
    font-weight: 600;
  }

  @include to-xl {
    padding-right: 16px;
  }

  &__content {
    width: 100%;
    word-break: break-word;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: v-bind("props.overflow");
  }
}
</style>

<template>
  <div class="message-box-list">
    <div
      v-for="messageItem in messageListWithStatus"
      :key="messageItem.id"
      class="message-box-item"
      data-t="message-box-item"
      :class="{ 'is-active': messageItem.status === MESSAGE_STATUS.ACTIVE }"
    >
      <slot
        name="item"
        v-bind="messageItem"
      >
        <MessageBox v-bind="messageItem" />
      </slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { getMessageStatus } from '../../mappers';
import { LoggedUser, MESSAGE_STATUS, MessageItemWithStatus, SupplierThreadDetails } from '../../model';
import MessageBox from './MessageBox.vue';

type Props = Pick<SupplierThreadDetails, 'admin' | 'messageList' | 'supplier'> & {
  loggedUser: LoggedUser
}
const props = defineProps<Props>();

const messageListWithStatus = computed<MessageItemWithStatus[]>(() => {
  return props.messageList.map((message) => ({
    ...message,
    status: getMessageStatus({
      loggedUser: props.loggedUser,
      user: message.user,
      supplier: props.supplier,
      admin: props.admin,
    }),
  }));
});
</script>
<style lang="scss" scoped>
.message-box-list {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 12px;
}

.message-box-item {
  max-width: 80%;
  width: max-content;

  &.is-active {
    margin-left: auto;
  }
}
</style>

<template>
  <div class="thread-header-new">
    <GlobalButton
      data-t="thread-header-new-button-back"
      class="button-back"
      @click.stop="handleBack"
    >
      <GlobalIcon
        name="chevron_backward"
        is-symbol
      />
    </GlobalButton>
    <span
      class="text"
      data-t="thread-header-new-text"
    >{{ text }}</span>
    <GlobalButton
      data-t="thread-header-new-button-close"
      class="button-close"
      @click.stop="handleClose"
    >
      <GlobalIcon name="close" />
    </GlobalButton>
  </div> 
</template>
<script setup lang="ts">
type Props = {
  isDraft: boolean
}
const props = defineProps<Props>();
const text = computed(() => props.isDraft ? 'Nowa wiadomość - szkic' : 'Nowa wiadomość');

const $emit = defineEmits<{
  (event: 'back'): void
  (event: 'close'): void
}>();
const handleBack = () => $emit('back');
const handleClose = () => $emit('close');
</script>
<style lang="scss" scoped>
.thread-header-new {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $c-tim-blue;
}

.button-back,
.button-close {
  width: 32px;
  height: 32px;
}

.text,
.icon-back,
.icon-close {
  color: $c-white;
}

.text {
  @include t5;
}
</style>

<template>
  <UiTableImportStatus
    :color="color"
    :label="label"
    :with-progress="withProgress"
    :progress-percent="progressPercent"
  />
</template>

<script setup lang="ts">
import { UiTableImportStatus } from '@ui-table';
import { IMPORT_CONFIGURATION_STATUS, IMPORT_PROCESSING_STAGE } from '@enums/global.enums';
import { IMPORT_CONFIGURATION_TRANSLATIONS, IMPORT_PROCESSING_FAILED_TRANSLATIONS, IMPORT_PROCESSING_STAGE_TRANSLATIONS } from '@helpers/enumsMaps';

interface Props {
  status?: IMPORT_CONFIGURATION_STATUS
  stage?: IMPORT_PROCESSING_STAGE
  progressPercent?: number,
}

interface ImportStatusData {
  customColor?: string
  label: string
}

const props = withDefaults(defineProps<Props>(), {
  status: IMPORT_CONFIGURATION_STATUS.NEW,
  stage: IMPORT_PROCESSING_STAGE.DOWNLOAD,
  progressPercent: 100,
});

const { t } = useI18n();

// NOTE: the color is blue for element that is processing and green for finished task as long as we dont provide custom color
const IMPORT_CONFIGURATION_STATUS_DATA: {[key in IMPORT_CONFIGURATION_STATUS]?: ImportStatusData } = {
  [IMPORT_CONFIGURATION_STATUS.NEW]: {
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.NEW]),
  },
  [IMPORT_CONFIGURATION_STATUS.SCHEDULED]: {
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.SCHEDULED]),
  },
  [IMPORT_CONFIGURATION_STATUS.PROCESSING]: {
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.PROCESSING]),
  },
  [IMPORT_CONFIGURATION_STATUS.PROCESSING_FAILED]: {
    customColor: 'red',
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.PROCESSING_FAILED]),
  },
  [IMPORT_CONFIGURATION_STATUS.MAPPING_CATEGORIES]: {
    customColor: 'blue',
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.MAPPING_CATEGORIES]),
  },
  [IMPORT_CONFIGURATION_STATUS.MAPPING_MANUFACTURERS]: {
    customColor: 'blue',
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.MAPPING_MANUFACTURERS]),
  },
  [IMPORT_CONFIGURATION_STATUS.MAPPING_UNITS]: {
    customColor: 'blue',
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.MAPPING_UNITS]),
  },
  [IMPORT_CONFIGURATION_STATUS.MAPPING_FINISHED]: {
    customColor: 'blue',
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.MAPPING_FINISHED]),
  },
  [IMPORT_CONFIGURATION_STATUS.CONFIRMED]: {
    customColor: 'blue',
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.CONFIRMED]),
  },
  [IMPORT_CONFIGURATION_STATUS.ACCEPTED]: {
    customColor: 'green',
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.ACCEPTED]),
  },
  [IMPORT_CONFIGURATION_STATUS.READY]: {
    customColor: 'green',
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.READY]),
  },
  [IMPORT_CONFIGURATION_STATUS.DECLINED]: {
    customColor: 'red',
    label: t(IMPORT_CONFIGURATION_TRANSLATIONS[IMPORT_CONFIGURATION_STATUS.DECLINED]),
  },
};

const color = computed(() => IMPORT_CONFIGURATION_STATUS_DATA[props.status]?.customColor as 'red' | 'blue' | 'green' | undefined);

const statusesWithProgress = [
  IMPORT_CONFIGURATION_STATUS.PROCESSING,
  IMPORT_CONFIGURATION_STATUS.PROCESSING_FAILED,
];
const withProgress = computed(() => statusesWithProgress.includes(props.status));

const label = computed(() => {
  if(props.status === IMPORT_CONFIGURATION_STATUS.PROCESSING){
    return t(IMPORT_PROCESSING_STAGE_TRANSLATIONS[props.stage] || '');
  }

  if(props.status === IMPORT_CONFIGURATION_STATUS.PROCESSING_FAILED) {
    return t(IMPORT_PROCESSING_FAILED_TRANSLATIONS[props.stage] || '');
  }

  return IMPORT_CONFIGURATION_STATUS_DATA[props.status]?.label || '';
});
</script>

import { toDateFormatWithTime } from '@ui-base';
import { THREAD_STATUS, SegmentItem, MessageCountItem, ThreadRequireUserAction, SegmentList, NotStandardSegment, MessageCountMap, MessageItem, FileItem, MESSAGE_STATUS, LoggedUser } from '../model';
import { USER_ROLE } from '@user-common';

export const isUserActionRequired = (payload: { status: string, isNewMessage: boolean }): ThreadRequireUserAction =>
  !!(payload.isNewMessage && payload.status === THREAD_STATUS.ACTIVE) as ThreadRequireUserAction;

export const getMessageStatus = (payload: {
  loggedUser: LoggedUser
  admin: {
    id: string,
  },
  supplier: {
    id: string,
  },
  user: {
    id: string,
    supplierId?: string
  },
}): MESSAGE_STATUS => {
  // wiadomość zalogowanego użytkownika
  if (payload.user.id === payload.loggedUser.id) {
    return MESSAGE_STATUS.ACTIVE;
  }

  const isSupplier = payload.loggedUser.role === USER_ROLE.ROLE_SUPPLIER;
  const isAdmin = payload.loggedUser.role === USER_ROLE.ROLE_ADMIN;

  if (isAdmin) {
    if (payload.user.supplierId === payload.supplier.id) {
      return MESSAGE_STATUS.ASSIGNED_SUPPLIER; // wiadomość dostawcy
    } else {
      return MESSAGE_STATUS.ADDITIONAL_ADMIN; // wiadomości innego admina
    }
  } else if (isSupplier) {
    if (payload.user.id === payload.admin.id) {
      return MESSAGE_STATUS.ASSIGNED_ADMIN; // wiadomości admina przypisanego do wątku
    } else {
      return MESSAGE_STATUS.ADDITIONAL_ADMIN; // wiadomości innego admina
    }
  }
};

export const mapMessageCountMap = (unMappedMessageCountItem: {
  active: number, 
  finished: number, 
  deleted: number, 
  all: number
}): MessageCountMap => ({
  active: unMappedMessageCountItem.active as MessageCountItem, 
  finished: unMappedMessageCountItem.finished as MessageCountItem, 
  deleted: unMappedMessageCountItem.deleted as MessageCountItem, 
  all: unMappedMessageCountItem.all as MessageCountItem, 
});

export const mapSegmentList = (unMappedSegmentList: {id: string, name: string}[]): SegmentList => {
  if (!unMappedSegmentList.length) {
    return null;
  }

  // NOTE: always first element is not standard one - BE rule
  const [notStandardSegment, ...restSegmentList]: SegmentItem[] = unMappedSegmentList.map(({ id, name }) => ({
    id: id as SegmentItem['id'],
    name: name as SegmentItem['name'],
  }));

  return {
    notStandardSegment: notStandardSegment as NotStandardSegment,
    restSegmentList,
  };
};

export const mapDisplayFile = (unMappedFile: { id: string, originalName: string, mimeType: string }): FileItem => ({
  id: unMappedFile.id as FileItem['id'],
  link: `/message_files/${unMappedFile.id}/file` as FileItem['link'],
  name: unMappedFile.originalName as FileItem['name'],
  type: unMappedFile.mimeType as FileItem['type'],
});

export const mapMessageItem = (unMappedMessage: {
  id: string,
  user: {
    id: string,
    name: string,
    role?: string,
    supplierId?: string,
  },
  createdAt: string,
  content: string,
  files: { id: string, originalName: string, mimeType: string }[],
}): MessageItem => {
  return {
    id: unMappedMessage.id as MessageItem['id'],
    user: {
      id: unMappedMessage.user.id as MessageItem['user']['id'],
      fullName: unMappedMessage.user.name as MessageItem['user']['fullName'],
      role: unMappedMessage.user.role as MessageItem['user']['role'],
      supplierId: unMappedMessage.user.supplierId as MessageItem['user']['supplierId'],
    },
    createdAt: toDateFormatWithTime(unMappedMessage.createdAt),
    content: unMappedMessage.content as MessageItem['content'],
    fileList: unMappedMessage.files.map(mapDisplayFile),
  };
};

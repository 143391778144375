<template>
  <GlobalNotification
    :model-value="isVisibleTemporaryNotification && properPage"
    :type="BASE_NOTIFICATION_TYPES.INFO"
    :closeable="true"
    @update:model-value="isVisibleTemporaryNotification = $event"
  >
    Firmy kurierskie sygnalizują już duży wzrost liczby przesyłek w związku ze zbliżającym się okresem przedświątecznym. W związku z tym odbiór przesyłek do zamówień, które zdecydowali się Państwo realizować kurierem TIM może się wydłużyć do kilku dni. Prosimy o realizację wysyłek kurierem własnym o ile jest to możliwe. Za utrudnienia najmocniej przepraszamy.
  </GlobalNotification>
</template>
<script setup lang="ts">
import { ROUTER_LINKS } from '@enums/global.enums';
import { BASE_NOTIFICATION_TYPES } from '../model';

const isVisibleTemporaryNotification = ref(true);
const $route = useRoute();
const properPage = computed(() => {
  if ($route.path === ROUTER_LINKS.HOME) {
    return true;
  }

  return [
    ROUTER_LINKS.SALES_ORDERS,
    ROUTER_LINKS.SALES_SHIPMENTS,
    ROUTER_LINKS.SALES_INVOICES,
  ].some((path) => $route.path.startsWith(path));
});
</script>

import { SUPPLIER_STATUS } from '@supplier-common';

export type UserRole = {
  id: string
  name: string
}

export type UserSession = {
  token?: string
}

export type CurrentUser = {
  id: string
  firstName: string
  lastName: string
  role: USER_ROLE
  unreadThreadsCount: number
  supplier: null | UserAsSupplier['supplier'],
}

type UserAsSupplier = {
  supplier: {
    id: string
    status: SUPPLIER_STATUS
  },
}

export enum USER_ROLE {
  ROLE_SUPPLIER = 'Supplier',
  ROLE_ADMIN = 'Administrator',
}

export enum USER_STATUS {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

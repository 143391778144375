<template>
  <li
    class="base-list-element"
    :class="listElementClasses"
  >
    <div
      class="base-list-element__group"
      data-t="base-list-element-item"
      @click="$emit('click:item')"
    >
      <div
        class="base-list-element__header"
        :class="{ 'base-list-element__header--single': !withSubheader }"
      >
        <slot />
      </div>
      <div
        v-if="withSubheader"
        class="base-list-element__subheader"
      >
        <slot name="subheader" />
      </div>
    </div>
    <GlobalIcon
      v-if="icon"
      :name="icon"
      class="base-list-element__icon"
      data-t="base-list-element-icon"
      @click="$emit('click:icon')"
    />
  </li>
</template>

<script lang="ts" setup>
interface Props {
  withSubheader?: boolean,
  dense?: boolean,
  active?: boolean,
  icon?: string,
}
const props = withDefaults(defineProps<Props>(), {
  withSubheader: false,
  dense: false,
  active: false,
  icon: '',
});
const $emit = defineEmits(['click:icon', 'click:item']);

const listElementClasses = computed(() => {
  return {
    'base-list-element--dense': props.dense,
    'base-list-element--active': props.active,
  };
});
</script>

<style lang="scss" scoped>

.base-list-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 12px;
  cursor: pointer;
  border-bottom: 1px solid $c-tim-gray-light;
  word-break: break-word;

  @include t7;

  &:last-child {
    border-bottom: none;
  }

  &__icon {
    width: 48px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 16px 0;
  }

  &__header {
    &--single {
      display: flex;
      align-items: center;
    }
  }

  &--dense {
    min-height: 48px;

    .base-list-element__group {
      padding: 0;
    }
  }

  &:hover,
  &--active {
    background: rgba($c-tim-gray-light, 0.37);
  }

  &__subheader {
    @include t10;
  }
}
</style>

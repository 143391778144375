import { ROUTER_LINKS } from '@enums/global.enums';
import { useUserCommon, USER_ROLE, useUserRoles } from '@user-common';
import { requestCaller } from '@helpers/functions';
import { reloadUser } from '@services/userService';
import { ADMIN_ROUTER_LINKS } from '@enums/admin.enums';
import { reloadPimcoreSession } from '@services/pimcoreService';
import { useEdiConfiguration } from '@composables/useSupplierDataByIdUser';
import { useFeatureFlag } from '@ui-base';
import { useSupplierCommon } from '@supplier-common';

export const checkAuth = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  // NOTE: Order matters
  const validatorList = [
    // 503 MAINTANANCE MODE
    isMaintananceMode,
    // REGISTRATION PANEL - TOKEN DOESN'T EXIST
    isRegistrationInactive,
    // REGISTRATION PANEL - REGISTRATION ALREADY EXISTS
    isAlreadyRegistered,
    // LOGIN PANEL - ROUTE NOT AUTHORIZE
    canAccessNotAuthorizePage,
    //  APP - NOT LOGIN - TOKEN DOESN'T EXIST
    isTokenMissing,
    // APP - NOT LOGIN - TOKEN EXISTS
    hasTokenButMissingUserData,
    // APP - LOGGED IN - USER INACTIVE
    isFirstLoginForSupplier,
    // APP - LOGGED IN - RESTRICTED FOR ADMIN
    isAdminRestricted,
    // APP - LOGGED IN - RESTRICTED FOR SUPPLIER
    isSupplierRestricted,
    // APP - LOGGED IN - EDI CONFIGURATION ENABLED
    blockAccessToEdiConfigurationPages,
    // APP - CHECK FEATURE FLAG
    hasFeatureFlag,
  ];

  for (const validator of validatorList) {
    const shouldReturn = await validator(to, from, next);
    // if first returns "true" then second will be not called, etc.
    if (shouldReturn) {
      return;
    }
  }

  next();
};

const getToken = () => localStorage.getItem('token');

type GuardValidator = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => Promise<boolean> | boolean

// redirect if token is neccessary
const isRegistrationInactive: GuardValidator = (to, from, next) => {
  const registerLinkInactive = to.meta.tokenRequired && !to.query.token;
  if(registerLinkInactive){
    next(ROUTER_LINKS.REGISTER_INACTIVE);
    return true;
  }
};

const isAlreadyRegistered: GuardValidator = (to, from, next) => {
  const alreadyExist = to.meta.tokenRequired && to.query.token === 'ALREADY_REGISTERED';
  if (alreadyExist) {
    next(ROUTER_LINKS.REGISTER_ALREADY_EXIST);
    return true;
  }
};

// allow not authorized area unless user is logged
const canAccessNotAuthorizePage: GuardValidator = (to, from, next) => {
  const allowNotAuthorized = to.meta.allowNotAuthorized;
  if(allowNotAuthorized){
    if(getToken()) {
      next(ROUTER_LINKS.HOME);
    } else {
      next();
    }
    return true;
  }
};

// redirect if user is not logged
const isTokenMissing: GuardValidator = (to, from, next) => {
  if(!getToken()) {
    next(ROUTER_LINKS.LOGIN);
    return true;
  }
};

// add user and supplier if user is null and already logged in
const hasTokenButMissingUserData: GuardValidator = async () => {
  const userCommon = useUserCommon();
  const supplierCommon = useSupplierCommon();

  if (getToken() && !userCommon.currentUser) {
    const [userData, error] = await requestCaller(reloadUser());
  
    if (error) {
      userCommon.logout();
      return true;
    }

    if (userData) {
      userCommon.setUser(userData.user);
      userCommon.setUserSession(userData);
      supplierCommon.setSupplierId(userData.user.supplier?.id);
      supplierCommon.setSupplierStatus(userData.user.supplier?.status);
    }

    // NOTE: Reload Pimcore session should be running parallel with TMD app user reload. We don't care much if it fails, because it is only small part of app.
    reloadPimcoreSession();
  }
};

const isFirstLoginForSupplier: GuardValidator = (to, from, next) => {
  const userRoles = useUserRoles();
  const supplierCommon = useSupplierCommon();
  if(userRoles.isSupplier && !supplierCommon.isSupplierActive && to.path !== ROUTER_LINKS.SETTINGS_SUPPLIER_ACCOUNT){
    next(ROUTER_LINKS.SETTINGS_SUPPLIER_ACCOUNT);
    return true;
  }
};

// check for routes only for admin role
const isAdminRestricted: GuardValidator = (to, from, next) => {
  const userRoles = useUserRoles();
  if(to.meta.role === USER_ROLE.ROLE_ADMIN){
    if(!userRoles.isAdmin) {
      // if user is not supplier then he is supplier
      next(ROUTER_LINKS.HOME);
      return true;
    }
  }
};

// check for routes only for supplier role
const isSupplierRestricted: GuardValidator = (to, from, next) => {
  const userRoles = useUserRoles();
  if(to.meta.role === USER_ROLE.ROLE_SUPPLIER){
    if(!userRoles.isSupplier) {
      // if user is not supplier then he is admin
      next(ADMIN_ROUTER_LINKS.ADMIN_PANEL);
      return true;
    }
  }
};

const isMaintananceMode: GuardValidator = (to, from, next) => {
  if (to.path === ROUTER_LINKS.ERROR_503) {
    next();
    return true;
  }
};

// When supplier has enabled edi configuration then we don't want to show him some pages
const blockAccessToEdiConfigurationPages: GuardValidator = async (to, from, next) => {
  const userRoles = useUserRoles();
  if (!userRoles.isSupplier) {
    return;
  }

  // redirection is for SALES_ORDERS and SALES_INVOICES and SALES_SHIPMENTS
  // so if user doesn't enter those pages then we don't need to fetch edi configuration
  if (
    [ROUTER_LINKS.SALES_ORDERS, ROUTER_LINKS.SALES_INVOICES, ROUTER_LINKS.SALES_SHIPMENTS].every((a) => !to.path.startsWith(a))
  ) {
    return;
  }

  const supplierCommon = useSupplierCommon();
  const { ediConfiguration, getEdiConfiguration } = useEdiConfiguration(supplierCommon.currentSupplier.id);

  await getEdiConfiguration();

  if (ediConfiguration.enabled) {
    next(ROUTER_LINKS.SALES_EDI_CONFIGURATION);
    return true;
  }
};

const hasFeatureFlag: GuardValidator = (to, from, next) => {
  if ('featureFlag' in to.meta) {
    const { isFlagEnabled } = useFeatureFlag(to.meta.featureFlag);
    if (!isFlagEnabled.value) {
      next(ROUTER_LINKS.HOME);
      return true;
    }
  }
};

<template>
  <div
    v-if="show"
    class="base-notification"
    :class="notificationClass"
  >
    <div class="base-notification__content">
      <img
        :src="`/icons/icon-${type.toLowerCase()}.svg`"
        :alt="type"
      >
      <slot />
    </div>
    <div
      v-if="closeable"
      class="base-notification__header"
    >
      <GlobalIcon
        name="close"
        class="base-notification__close"
        @click="show = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { BASE_NOTIFICATION_TYPES } from '../model';

interface Props {
  modelValue: boolean,
  type: BASE_NOTIFICATION_TYPES,
  closeable?: boolean,
  margin?: string
}
const props = withDefaults(defineProps<Props>(), {
  closeable: true,
  margin: '20px 0',
});
const $emit = defineEmits(['update:modelValue']);

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    $emit('update:modelValue', value);
  },
});
const notificationClass = computed(() => {
  return {
    'base-notification--error': props.type === BASE_NOTIFICATION_TYPES.ERROR,
    'base-notification--success': props.type === BASE_NOTIFICATION_TYPES.SUCCESS,
    'base-notification--info': props.type === BASE_NOTIFICATION_TYPES.INFO,
  };
});
</script>

<style lang="scss" scoped>

.base-notification {
  width: 100%;
  background-color: $c-white;
  margin: v-bind('props.margin');
  padding: 15px 24px;
  box-shadow: $base-shadow;
  position: relative;
  border-radius: $base-radius;
  display: flex;

  &__header {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__close {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }

    :deep(span) {
      white-space: normal;
    }
  }

  &--success {
    background-color: $c-tim-green-light;

    .base-notification__content {
      color: $c-tim-green;

      img {
        fill: $c-tim-green;
      }
    }

    .base-notification__close {
      color: $c-tim-green;
    }
  }

  &--info {
    background-color: $c-tim-blue-light;

    .base-notification__content {
      color: $c-tim-blue-dark;

      img {
        fill: $c-tim-blue-dark;
      }
    }

    .base-notification__close {
      color: $c-tim-blue-dark;
    }
  }

  &--error {
    background-color: $c-tim-red-light;

    .base-notification__content {
      color: $c-tim-red;

      img {
        fill: $c-tim-red;
      }
    }

    .base-notification__close {
      color: $c-tim-red;
    }
  }
}
</style>

<template>
  <div
    class="panel-trigger"
    :class="{ 'is-active': isActive }"
  >
    <GlobalIcon name="mail" />
    <span
      v-if="unreadThreadsCount > 0"
      class="panel-trigger__message-count"
      data-t="panel-trigger-count"
    >{{ nomalizedUnreadThreadsCount }}</span>
  </div>
</template>

<script setup lang="ts">
type Props = {
  isActive: boolean
  unreadThreadsCount: number
}
const props = defineProps<Props>();

const nomalizedUnreadThreadsCount = computed(() => {
  return props.unreadThreadsCount > 99 ? '99' : props.unreadThreadsCount;
});
</script>

<style lang="scss" scoped>
.panel-trigger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  transition: background-color $base-transition;
  cursor: pointer;

  &.is-active,
  &:hover {
    background: $c-tim-blue-light;
  }

  &__message-count {
    position: absolute;
    top: 4px;
    left: 24px;
    padding: 2px 4px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    background: $c-tim-accent;
    border: 2.4px solid $c-white;
    border-radius: 8px;
  }
}
</style>

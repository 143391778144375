<template>
  <GlobalDropdown
    v-model="isOpen"
    :offset="[0, 4]"
    is-click-only
    :close-on-content-click="false"
  >
    <template #trigger>
      <PanelTrigger
        data-t="panel-box-button"
        :is-active="isOpen"
        :unread-threads-count="unreadThreadsCount"
      />
    </template>
    <div
      data-t="panel-box-content"
      class="content"
      :style="{ height }"
    >
      <slot />
    </div>
  </GlobalDropdown>
</template>
<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { SUPPLIER_PANEL_HEIGHT, SUPPLIER_PANEL_EXPANDED_HEIGHT } from '../../model/Constrains';
import PanelTrigger from '../common/PanelTrigger.vue';

type Props = {
  isOpen: boolean
  isExpanded: boolean
  unreadThreadsCount: number
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'update:isOpen', isOpen: boolean): void;
}>();
const isOpen = useVModel(props, 'isOpen', $emit);

const height = computed(() => props.isExpanded ? SUPPLIER_PANEL_EXPANDED_HEIGHT : SUPPLIER_PANEL_HEIGHT);
</script>
<style lang="scss" scoped>
.content {
  max-width: 100vw;
  max-height: 100vh;
  width: 560px;
  transition: height $base-transition;
  border-radius: $base-radius;
  box-shadow: $dropdown-shadow;
  overflow: hidden;
}
</style>

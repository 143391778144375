export default {
  warehouseAddressSupplier: {
    notification: 'Jest to adres stałego punktu odbioru przesyłek przy zamawianiu kuriera od TIM. Przy zmawianiu kuriera od TIM przewoźnikiem DPD dla odbioru przesyłek w rozmiarze paczka, adres ten podstawi się domyślnie w pole adres nadawcy na zleceniu transportowym, lecz możesz go na tym zleceniu dowolnie edytować. Przy zamawianiu kuriera od TIM dla przesyłek w rozmiarze “paleta” adres stałego punktu odbioru musi być wcześniej zdefiniowany i potwierdzony przez przewoźnika. Możesz mieć kilka punktów stałego odbioru przesyłek.',
    addNextAddress: 'Dodaj kolejny adres',
    form: {
      warehouseName: 'Nazwa firmy/magazynu',
      street: 'Ulica',
      buildingNumber: 'Numer budynku',
      city: 'Miejscowość',
      postCode: 'Kod pocztowy',
      collectRestrictions: 'Informacje transportowe',
    },
    emptyResults: 'Brak adresów',
    editFormHeader: 'Edytuj adres',
    addFormHeader: 'Dodaj adres',
    collectRestrictionsTooltip: 'Wpisz, jakie kurier może mieć ograniczenia przy odbiorze przesyłki “np. “wąska brama” lub “dojazd od ulicy…”',
  },
} as const;

import { useCookies } from '@vueuse/integrations/useCookies';

export const initPackage = () => {
  const isProduction = import.meta.env.VITE_BACKEND_URL.includes('dostawca.tim.pl');
  const cookies = useCookies();

  if (!isProduction && !cookies.get('WITH_GTM')) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  function gtag(key?: string, value?: string | Date)
  function gtag () {
    /*eslint prefer-rest-params: "off"*/
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-G38QE5L5J1');

  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-G38QE5L5J1';
  document.head.appendChild(script);
};

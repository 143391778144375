<template>
  <Form
    ref="form"
    class="new-thread-form"
    @submit="handleSubmit"
  >
    <BaseTextInput
      id="segmentName"
      :model-value="segment.name"
      name="segmentName"
      is-disabled
    />
    <BaseTextInput
      id="messageTitle"
      v-model="title"
      name="messageTitle"
      label="Tytuł wiadomości"
      rules="requiredRule|maxLength:255"
      :is-disabled="isLoading"
    />
    <BaseTextarea
      id="messageContent"
      v-model="messageContent"
      rules="requiredRule"
      label="Napisz wiadomość"
      name="messageContent"
      max-length="3000"
      :max-height="431"
      rows="10"
      :is-disabled="isLoading"
    />
    <FileAddBox @update:file-list="handleFileListUpdate" />
    <span class="separate-line" />
    <div class="button-wrapper">
      <GlobalButton
        type="button"
        outlined
        no-border
        data-t="new-thread-form-cancel-button"
        :disabled="isLoading"
        @click.stop="handleCancel"
      >
        Anuluj
      </GlobalButton>
      <GlobalButton
        type="submit"
        icon="email"
        :loading="isLoading"
        data-t="new-thread-form-submit-button"
      >
        Wyślij wiadomość
      </GlobalButton>
    </div>
  </Form>
</template>
<script setup lang="ts">
import { BaseTextarea, BaseTextInput } from '@ui-form';
import { Form } from 'vee-validate';
import { SegmentItem } from '../../model';
import FileAddBox from '../common/file/FileAddBox.vue';
import { CreateSupplierThread } from '../../model/Payload';
import { ValidatedFile } from '../../model/File';

type Props = {
  isLoading?: boolean
  segment: SegmentItem
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'cancel'): void
  (event: 'submit', payload: CreateSupplierThread): void
}>();

const title = ref('');
const messageContent = ref('');
const fileList = ref<ValidatedFile[]>([]);

const handleFileListUpdate = (files: ValidatedFile[]) => {
  fileList.value = files;
};

const form = ref(null);
const handleSubmit = async () => {
  const result = await form.value.validate();
  if (!result.valid || fileList.value.some((file) => file.isInvalidSize)) {
    return;
  }

  $emit('submit', {
    segmentId: props.segment.id,
    title: title.value as CreateSupplierThread['title'],
    messageContent: messageContent.value as CreateSupplierThread['messageContent'],
    files: fileList.value,
  });
};

const handleCancel = () => {
  title.value = '';
  messageContent.value = '';
  fileList.value = [];
  $emit('cancel');
};
</script>
<style lang="scss" scoped>
.new-thread-form {
  display: grid;
  gap: 16px;
  padding: 16px;
  background: $c-tim-gray-lightest-alt;
  height: max-content;
}

.separate-line {
  display: flex;
  width: 100%;
  height: 1px;
  background: $c-tim-gray-light;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

import axios, { CancelTokenSource } from 'axios';
import { ABORT_TOKEN_ERROR } from './model';

const tokenSet = new Map<string, CancelTokenSource>();
export const abortToken = (key: string) => {
  if (tokenSet.has(key)) {
    tokenSet.get(key)!.cancel(ABORT_TOKEN_ERROR);
    tokenSet.delete(key);
  }

  tokenSet.set(key, axios.CancelToken.source());

  return tokenSet.get(key)!.token;
};

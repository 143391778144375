import { FormatedDate, FormatedDateWithTime, FormatedPrice } from './model';

const createFormat = (fileName: string, format: string) => format && !fileName.includes(`.`) ? `.${format}` : '';
export const downloadFile = (data: Blob, fileName = 'file', format = 'pdf') => {
  const href = URL.createObjectURL(data);
  
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${fileName}${createFormat(fileName, format)}`); //or any other extension
  document.body.appendChild(link);
  link.click();
  
  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

function toLocalTime(isoString: string) {
  const date = new Date(isoString);
  return date.toLocaleTimeString(
    [],
    {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    },
  );
}
export const toDateFormat = (value?: string): FormatedDate => {
  if (!value) {
    return '-' as FormatedDate;
  }

  const date = new Date(value).toJSON()
    ?.slice(0, 10);

  if (!date) {
    return '-' as FormatedDate;
  }

  return date as FormatedDate;
};
export const toDateFormatWithTime = (value?: string): FormatedDateWithTime => {
  const date = toDateFormat(value);
  const time = toLocalTime(String(value));

  if (date === '-' || time === 'Invalid Date') {
    return '-' as FormatedDateWithTime;
  }

  return `${date} ${time}` as FormatedDateWithTime;
};

export const toPriceFormat = (value: number, withCurrency = true): FormatedPrice => {
  if (value === null || value === undefined) {
    return '-' as FormatedPrice;
  }

  const price = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' })
    .format(value)
    .replace(',', '.');

  if (isNaN(value)) {
    return '-' as FormatedPrice;
  }

  return (withCurrency ? price : price.replace('zł', '').trim()) as FormatedPrice;
};

export const isLinkExternal = (value: string): boolean => /^(http:|https:|ftp:|mailto:)/.test(value);

<template>
  <div class="file-display">
    <img
      v-if="isFileImage(file.type)"
      data-t="file-display-icon"
      :src="file.link"
    >
    <GlobalIcon
      v-else
      data-t="file-display-icon"
      name="file_present"
    />
    <div class="text-list">
      <span
        class="file-name"
        :title="file.name"
        data-t="file-display-name"
      >{{ file.name }}</span>
      <span
        class="file-extention"
        :title="file.name"
        data-t="file-display-extention"
      >Plik {{ extention }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { isFileImage } from '../../../helpers';
import { FileItem } from '../../../model';

type Props = {
  file: FileItem;
}
const props = defineProps<Props>();

const extention = computed(() => props.file.type.split('/').pop()
  .toLocaleUpperCase());
</script>
<style lang="scss" scoped>
.file-display {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 11px; 
}

.text-list {
  display: grid;
}

.file-name {
  @include t7;
  @include one-line-text-with-dots;

  margin-bottom: 2px;
}

.file-extention {
  @include t9;
  @include one-line-text-with-dots;
}

img {
  max-height: 42px;
  max-width: 48px;
  object-fit: cover;
}
</style>

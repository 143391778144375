<template>
  <div class="content-loader">
    <GlobalLoader />
  </div>
</template>
<style lang="scss" scoped>
.content-loader {
  background: $c-white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

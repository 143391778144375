<template>
  <PanelBox
    v-model:is-open="isOpen"
    :is-expanded="isExpanded"
    :unread-threads-count="userCommon.unreadThreadsCount"
  >
    <EmptyThreadList
      v-if="currentStep === STEP.EMPTY_THREAD_LIST"
      :is-loading="isLoading.list || isLoading.segmentList"
      :segment-list="segmentList"
      @segment-selected="handleOpenNewThread"
    >
      <template #header>
        <ThreadHeaderExpand
          v-model:is-expanded="isExpanded"
          :message-count="messageCountList.all"
        />
      </template>
    </EmptyThreadList>
    <ThreadList
      v-else-if="currentStep === STEP.THREAD_LIST"
      :is-loading="isLoading.list || isLoading.messageCountList"
      :active-status="activeStatus"
      :is-expanded="isExpanded"
      :list="threadList"
      :total="currentTotal"
      :message-count-list="messageCountList"
      @clicked-thread-item="handleOpenThreadDetails"
      @clicked-create-new-thread="handleOpenSegmentSelection"
      @update:active-status="handleStatusChange"
      @next-page="({ page }) => fetchThreadList({ page, action: 'append' })"
    >
      <template #header>
        <ThreadHeaderExpand
          v-model:is-expanded="isExpanded"
          :message-count="messageCountList.all"
        />
      </template>
    </ThreadList>
    <SegmentSelection
      v-else-if="currentStep === STEP.SEGMENT_SELECTION"
      :is-loading="isLoading.segmentList"
      :segment-list="segmentList"
      @segment-selected="handleOpenNewThread"
    >
      <template #header>
        <ThreadHeaderNew
          :is-draft="false"
          @close="handleClose"
          @back="handleBackToThreadList"
        />
      </template>
    </SegmentSelection>
    <NewThread
      v-else-if="currentStep === STEP.NEW_THREAD"
      :segment="selectedSegment"
      @cancel="handleBackToSegmentSelection"
      @thread-created="handleThreadCreated"
    >
      <template #header>
        <ThreadHeaderNew
          :is-draft="true"
          @close="handleClose"
          @back="handleBackToSegmentSelection"
        />
      </template>
    </NewThread>
    <ThreadDetails
      v-else-if="currentStep === STEP.THREAD_DETAILS"
      :thread-details="threadDetails"
      :logged-user="userCommon.currentUser"
      @message-sent="fetchThreadDetails(threadDetails.id)"
    >
      <template #header>
        <ThreadHeaderDetails
          :thread-details="threadDetails"
          @close="handleClose"
          @back="handleBackToThreadList"
        />
      </template>
    </ThreadDetails>
    <ContentLoader v-else />
  </PanelBox>
</template>
<script setup lang="ts">
import { SegmentItem, SupplierThreadItem, THREAD_STATUS } from '../../model';
import PanelBox from './PanelBox.vue';
import ThreadHeaderExpand from './header/ThreadHeaderExpand.vue';
import ThreadHeaderNew from './header/ThreadHeaderNew.vue';
import ThreadHeaderDetails from './header/ThreadHeaderDetails.vue';
import EmptyThreadList from './panel-views/EmptyThreadList.vue';
import SegmentSelection from './panel-views/SegmentSelection.vue';
import ThreadList from './panel-views/ThreadList.vue';
import NewThread from './panel-views/NewThread.vue';
import ThreadDetails from './panel-views/ThreadDetails.vue';
import { useCommonState } from '../../composables/supplier/useCommonState';
import { storeToRefs } from 'pinia';
import { useUserCommon } from '@user-common';
import ContentLoader from '../common/ContentLoader.vue';
import { useLocalStorage } from '@vueuse/core';
import { usePullingMachine } from '../../composables/common/usePullingMachine';

// KEEPS TRACK OF EXPAND STATE
const isExpanded = useLocalStorage('communication-module-supplier-is-expanded', false);

const userCommon = useUserCommon();

// THREAD STATE
const {
  activeStatus,
  isLoading,
  threadList,
  currentTotal,
  threadDetails,
  messageCountList,
  segmentList,
} = storeToRefs(useCommonState());
const {
  fetchThreadDetails,
  fetchThreadList,
  fetchSegmentList,
  fetchMessageCountList,
  refreshThreadList,
  refreshThreadDetails,
  refreshUnreadThreadsCount,
  refreshMessageCountList,
} = useCommonState();

const selectedSegment = ref<SegmentItem | null>(null);

enum STEP {
  THREAD_LIST,
  EMPTY_THREAD_LIST,
  THREAD_DETAILS,
  SEGMENT_SELECTION,
  NEW_THREAD,
}
const currentStep = ref<STEP>(null);

const setInitStep = () => {
  currentStep.value = threadList.value.length
    ? STEP.THREAD_LIST
    : STEP.EMPTY_THREAD_LIST;
};

const fetchThreadListWithStepAssign = async () => {
  await fetchThreadList({ page: 1 });
  setInitStep();
};

// CLOSE ACTION
const isOpen = ref(false);
const handleClose = () => {
  setInitStep();
  isOpen.value = false;
};

// INIT
watch(
  () => isOpen.value,
  () => {
    if (isOpen.value && currentStep.value === null) {
    // Always fetch ACTIVE threads on start
      fetchThreadListWithStepAssign();
      // Always fetch segment list on start
      fetchSegmentList();
      // Always fetch message count list on start
      fetchMessageCountList();
    }
  });

// CHANGE ACTIONS
const handleStatusChange = (status: THREAD_STATUS) => {
  activeStatus.value = status;
  fetchThreadList({ page: 1 });
};

// BACK ACTIONS
const handleBackToSegmentSelection = () => {
  isExpanded.value = true;
  if (threadList.value.length) {
    currentStep.value = STEP.SEGMENT_SELECTION;
  } else {
    currentStep.value = STEP.EMPTY_THREAD_LIST;
  }
};
const handleBackToThreadList = () => {
  fetchThreadList({ page: 1 });
  isExpanded.value = false;
  currentStep.value = STEP.THREAD_LIST;
};
const handleThreadCreated = () => {
  fetchThreadList({ page: 1 });
  fetchMessageCountList();
  isExpanded.value = false;
  currentStep.value = STEP.THREAD_LIST;
};

// FORWARD ACTIONS
const handleOpenThreadDetails = (payload: Pick<SupplierThreadItem, 'id'>) => {
  fetchThreadDetails(payload.id);
  isExpanded.value = true;
  currentStep.value = STEP.THREAD_DETAILS;
};
const handleOpenSegmentSelection = () => {
  isExpanded.value = true;
  currentStep.value = STEP.SEGMENT_SELECTION;
};
const handleOpenNewThread = (segment: SegmentItem) => {
  selectedSegment.value = segment;
  isExpanded.value = true;
  currentStep.value = STEP.NEW_THREAD;
};

const { createPulling } = usePullingMachine();
createPulling(refreshThreadList, 5 * 60 * 1000, isOpen); // refresh thread list every 5min
createPulling(refreshMessageCountList, 5 * 60 * 1000, isOpen); // refresh message count every 5min
createPulling(refreshUnreadThreadsCount, 5 * 60 * 1000, isOpen); // refresh unread message count every 5min
createPulling(refreshThreadDetails, 15 * 1000, isOpen); // refresh thread details every 15s
</script>

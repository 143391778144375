import { ShallowReactive } from 'vue';
import useValidation from '@composables/useValidation';

export const useFormItemState = (
  value: Ref<string | number>,
  props: ShallowReactive<{ name: string, isDisabled: boolean, rules: string, placeholder?: string, label?: string }>,
) => {
  // error state
  const { errorMessage, isInvalidState } = useValidation(props.name);

  // focused state
  const isFocused = ref(false);
  const handleFocus = () => {
    isFocused.value = true;
  };
  const handleBlur = () => {
    isFocused.value = false;
  };

  // active state
  const isActive = computed(() => !!value.value);

  const inputContainerClasses = computed(() => {
    return {
      'is-invalid': isInvalidState.value,
      'is-focused': isFocused.value,
      'is-active': isActive.value,
      'is-disabled': props.isDisabled,
    };
  });

  const isRequired = computed(() => props.rules.includes('requiredRule'));

  const placeholder = computed(() => (isActive.value || isFocused.value || !props.label) ? props.placeholder : '');

  return {
    errorMessage,
    handleFocus,
    handleBlur,
    inputContainerClasses,
    isRequired,
    isFocused,
    placeholder,
  };
};

<template>
  <div
    :row-id="rowId"
    class="row-item"
    :class="[{ 'row-item--hover': hover, 'row-item--expended': isExpanded }, $attrs.class]"
    :data-t="$attrs['data-t']"
  >
    <slot />
    <div
      v-if="$slots.options"
      class="row-options"
    >
      <slot name="options" />
    </div>
  </div>
  <div
    v-if="isExpanded"
    class="row-item__expanded-content"
  >
    <slot name="expanded-content" />
  </div>
</template>

<script setup lang="ts">
import { ROUTER_LINKS } from '@enums/global.enums';

interface Props {
  rowId: string | number
  backgroundColor?: string
  hover?: boolean
  isExpanded?: boolean,
  to?: ROUTER_LINKS | RouteLocationRaw,
}

const props = withDefaults(defineProps<Props>(), {
  backgroundColor: '#fff',
  hover: false,
  isExpanded: false,
  to: undefined,
});

provide('tableRowLink', props.to);
</script>

<style lang="scss" scoped>

.row-options {
  background-color: v-bind("props.backgroundColor");
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity $base-transition;
  z-index: 1;
  box-shadow: -1px 0 1px $c-tim-gray-light;

  @include to-xl {
    opacity: 1;
    pointer-events: all;
    transform: translateX(100%);
    transition: transform $base-transition;
  }
}

.row-item {
  min-height: 48px;
  max-height: 100px;
  padding: 4px 16px;
  background-color: v-bind("props.backgroundColor");
  border-bottom: 1px solid $c-tim-gray-light;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  position: relative;

  @include to-xl {
    overflow: hidden;
  }

  &--hover {
    &:hover {
      box-shadow: 0 1px 8px rgba($c-black, 0.25);
      z-index: 1;

      .row-options {
        opacity: 1;
        pointer-events: all;
        transform: translateX(0);

        @include to-xl {
          transform: translateX(0);
        }
      }
    }
  }

  &--expended {
    border-bottom: 0;
  }

  &__expanded-content {
    border-bottom: 1px solid $c-tim-gray-light;
  }
}
</style>
